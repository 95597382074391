import { DatePicker,Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { getHolidayById,editHoliday } from '../../../service/holidayService';
import propTypes from 'prop-types';
import Swal from 'sweetalert2';
import {Select} from 'antd';
import { getAllHolidays } from '../../../service/holidayService';
import moment from 'moment';
const EditHolidays = ({ onHolidayAdded, holidayId }) => {
  const [name, setName] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState(true);
    const [duration, setDuration] = useState(0);
    const [academic, setAcademic] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState([]);
    useEffect(() => {
      if (holidayId) {
        setSelectedHoliday(holidayId);
      }
    }, [holidayId]);
  
    const setSelectedHoliday = async (holidayId) => {
      try {
        const response = await getHolidayById(holidayId);
        if (response.data) {
          const holiday = response.data.holiday;
          setStartDate(dayjs(holiday.startDate));
          setEndDate(dayjs(holiday.endDate));
          setName(holiday.name)
          setStatus(holiday.status)
          setDateRange(holiday.daysDetail)
          setAcademic(holiday.holidayType)
        console.log(holiday,"THIS IS HOLIDAY")
          
        } else {
          console.error('Failed to fetch holiday data');
        }
      } catch (error) {
        console.error('Error fetching holiday data:', error);
      }
    };

console.log(holidayId)
       useEffect(() => {
        if (startDate && endDate) {
            if (endDate.isBefore(startDate, 'day')) {
                showWarning('End date cannot be earlier than start date.');
                setEndDate(null);
                setDuration(0);
            } else {
                const diff = endDate.diff(startDate, 'days') + 1;
                setDuration(diff);
            }
        
        } else {
            setDuration(0);
        }
    }, [startDate, endDate]);
    
    const leaveDayOptions = [
        { value: "Full Day", label: "Full Day", dayValue: 1 },
        { value: "Half Day-Morning", label: "Half Day/Morning", dayValue: 0.5 },
        { value: "Half Day-Evening", label: "Half Day/Evening", dayValue: 0.5 },
        { value: "Flexible", label: "Flexible", dayValue: 1 },
      ];

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };
    const formatDate = (date) => {
        const d = new Date(date);
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        const year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
    
        return [year, month, day].join("-");
      };

    const handleEndDateChange =async (date) => {
        setEndDate(date);
        if (startDate && date){
            const start = startDate.$d;
            const end = date.$d;
        
            
              const daysArray = [];
              let currentDate = new Date(start);
              while (currentDate.getTime() <= end.getTime()) {
                daysArray.push({
                  date: formatDate(currentDate),
                  day: null,
                });
                currentDate.setDate(currentDate.getDate() + 1);
               
              }
              console.log(daysArray,"Array")
              setDateRange(daysArray);
              console.log(dateRange)
            //   setLeaveDays(daysArray);
            } else {
              setDateRange([]);
            //   setLeaveDays([]);
            //   console.log("");
            }
    };

    const handleLeaveTypeChange = (index, selectedOption) => {
    
        const updatedLeaveDays = [...dateRange];
       // updatedLeaveDays[index].leaveType = selectedOption.label;
        updatedLeaveDays[index].day = selectedOption;
        console.log(updatedLeaveDays,"Leave Days")
        setDateRange(updatedLeaveDays);
      };
    

    const handleStatusChange = (e) => {
        setStatus(e.target.checked);
    };
    const handleSelectChange = (selectedOption) => {
        setAcademic(selectedOption);
    };
    const options = [
        { value: "Academic", label: "Academic" },
        { value: "Non-Academic", label: "Non-Academic" },
        { value: "Common", label: "Common (Academic & Non-Academic)" }
    ];
           //////////////////////////
           const search="";
           const [data, setData] = useState([]);
           const [, setFilteredData] = useState([]);

           const fetchHolidays = async (search = '') => {
               await getAllHolidays({
                   search,
                   setData: setData,
                   
               });
           };

    useEffect(() => {
        const fetchAndFilterHolidays = async () => {
            if (startDate || endDate) {

                fetchHolidays(search);

                let filtered = data;
                if (startDate) {
                    const startMonth = moment(startDate.toDate()).month();
                    filtered = filtered.filter(date =>
                        moment(date.startDate).month() === startMonth
                    );
                }

                if (endDate) {
                    const endMonth = moment(endDate.toDate()).month();
                    filtered = filtered.filter(date =>
                        moment(date.startDate).month() === endMonth
                    );
                }

                setFilteredData(filtered);
            }
            else {
                setFilteredData([])
            }
        };

        fetchAndFilterHolidays();

    }, [startDate, endDate]);
 
    

    const handleSubmit = async () => {
        if (!name || !startDate || !endDate||(academic===null)) {
            showWarning("Please fill in the required fields.");
            return;
        }
      

        const holiday = {
            name,
            startDate:formatDate(startDate) ,
            endDate:formatDate(endDate),
            status,
            duration,
            holidayType:academic,
            daysDetail:dateRange
        };
        console.log(holiday)

        try {
            console.log('Holiday OBJ', holiday);
            setLoading(true)
            const response = await editHoliday(holidayId, holiday);
            console.log('Holiday added successfully', response);
          
            onHolidayAdded(holiday);
            handleReset();  
            setLoading(false)
            if(response.status==="success"){
               showSuccess() 
            }

        } catch (error) {
            console.error('Error adding holiday:', error);
        }
    };

    const handleReset = () => {
        setName('');
        setStartDate(null);
        setEndDate(null);
        setStatus(true);
        setDuration(0);
        setAcademic(null)
        setDateRange([])
        //setError('');
    };
    const showWarning = (str) =>{
        return Swal.fire({
          title: "Error",
          text: str,
          icon: "error",
        });
      }
      const showSuccess = () => {
        return Swal.fire({
          title: "Good job!",
          text: "Successfully Updated!",
          icon: "success",
        });
      };
 
    return (
        <div>
            {/* Add Department */}
            <div className="modal fade" id="edit-department">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Edit Holiday</h4>
                                    </div>
                                    {/* <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button> */}
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label>Add Holiday</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <div className="input-blocks">
                                                        <label>Select Holiday Type</label>
                                                        <Select
                                                            value={academic}
                                                            className='select'
                                                            options={options}
                                                            placeholder="Select holiday type"
                                                            onChange={handleSelectChange}
                                                            //isClearable
                                                            dropdownStyle={{ zIndex: 9999 }}
                                                            style={{ width: "100%", height: "40px" ,}}
                                                        
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Start Date</label>
                                                    <div className="input-groupicon calender-input">
                                                        <DatePicker
                                                            value={startDate}
                                                            onChange={handleStartDateChange}
                                                            type="date"
                                                            className="filterdatepicker"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholder='20-2-2024'
                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                            // disabledDate={disabledStartDate}
                                                            defaultPickerValue={endDate || dayjs()}
                                                            style={{
                                                                width: "100%",
                                                                boxSizing: "border-box"
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>End Date</label>
                                                    <div className="input-groupicon calender-input">
                                                        <DatePicker
                                                            value={endDate}
                                                            onChange={handleEndDateChange}
                                                            type="date"
                                                            className="filterdatepicker"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholder='20-2-2024'
                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                            // disabledDate={disabledEndDate}
                                                            defaultPickerValue={startDate || dayjs()}
                                                            style={{
                                                                width: "100%",
                                                                boxSizing: "border-box"
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {dateRange.map((item, index) => (
                                                <div key={index} className="col-lg-12">
                                                    <div className="apply-leave">
                                                        <div className="leave-apply">
                                                            <div className="leave-date">
                                                                <p>{formatDate(item.date)}</p>
                                                            </div>
                                                            <div  style={{ width: '100%' }}>
                                                                <div className="input-blocks mb-0">
                                                                    <Select
                                                                        className="select"
                                                                        value={item.day}
                                                                        options={leaveDayOptions}
                                                                        placeholder="Select holiday type"
                                                                        onChange={(selectedOption) =>
                                                                            handleLeaveTypeChange(index, selectedOption)
                                                                        }
                                                                        dropdownStyle={{ zIndex: 9999 }}
                                                                        style={{ width: "100%", height: "40px" ,}}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="col-lg-12">
                                                <div className="input-blocks">
                                                    <label>No of Days</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="01"
                                                        value={duration}
                                                        disabled
                                                    />
                                                </div>
                                            </div>


                                            <div className="input-blocks m-0 pb-1">
                                                <div
                                                    className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                    <span className="status-label">Status</span>
                                                    <input
                                                        type="checkbox"
                                                        id="user5"
                                                        className="check"
                                                        checked={status}
                                                        onChange={handleStatusChange}
                                                    />
                                                    <label htmlFor="user5" className="checktoggle">
                                                        {" "}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",

                                        }} className="mt-4">
                                            <button
                                                type="button"
                                                className="btn btn-cancel"
                                                data-bs-dismiss="modal"
                                                onClick={handleReset}
                                            >
                                                Close
                                            </button>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-reset me-2"
                                                    onClick={handleReset}
                                                >
                                                Reset
                                    </button>
                                    {(!name || !startDate || !endDate||(academic===null))?(
                                    <button
                                                type="button"
                                                className="btn btn-submit"
                                                onClick={handleSubmit}
                                                
                                            >
                                                {loading ? <Spin size="small" /> : "Save"}
                                    </button>
                                    ):(
                                        <button
                                                type="button"
                                                className="btn btn-submit"
                                                onClick={handleSubmit}
                                                data-bs-dismiss="modal"
                                            >
                                                {loading ? <Spin size="small" /> : "Save"}
                                    </button>
                                    )}
                                </div>
                                </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Department */}
        </div>
    );
};
EditHolidays.propTypes = {
  onHolidayAdded: propTypes.func.isRequired,
  holidayId: propTypes.string.isRequired,
};

export default EditHolidays;
