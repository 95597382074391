
import Swal from "sweetalert2";
import axios from "axios";

const showWarning = (str) =>{
  return Swal.fire({
    title: "Error",
    text: str,
    icon: "error",
  });
}
//get all holiday (search)
  export const getAllHolidays = async ({ search, setData}) => {
    try {
      
      await axios.get(`/holidays?search=${search}`).then((res) => {
        
        setData(res.data.data.holidays);
      }).catch(() => {
        
        showWarning("Error occurred while fetching Holidays");
      });
    } catch (error) {
      
      showWarning("Error occurred while fetching Holidays");
    }
  };
  export const createHoliday = async (holiday) => {
  try {
    const response = await axios.post(
      `/holidays`,holiday
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response.data.message || "Failed to create"
    );
  }
};




  //Update holiday
  export const createHolidayMehod = async ({ data, setSuccess, setLoading }) => {
    try {
      setLoading(true);
      const url = data.id ? `/holidays/${data.id}` : `/holidays`;
      const method = data.id ? 'put' : 'post';
      
      await axios[method](url, data).then(() => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => setSuccess(false), 1000);
      }).catch(() => {
        setLoading(false);
        showWarning("Error occurred while saving Holiday");
      });
    } catch (error) {
      setLoading(false);
      showWarning("Error occurred while saving Holiday");
    }
  };
  

  //delete holiday
  export const deleteHoliday = async ({id}) => {
    try {
      await axios.delete(`/holidays/${id}`).then(() => {
        // setIsDelete(true);
        // setTimeout(() => setIsDelete(false), 1000);
      }).catch((err) => {
        if (err.response.status === 404) {
          showWarning(err.response.data.message);
          return;
        }
        showWarning("Error occurred while deleting Holiday");
      });
    } catch (error) {
      showWarning("Error occurred while deleting Holiday");
    }
  };


  //get holiday by ID
  export const getHolidayById = async (holidayId) => {
    try {
      const response = await axios.get(`/holidays/${holidayId}`, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Failed to fetch holiday details");
    }
  };
  

  //edit holiday
  export const editHoliday = async (holidayId, holidayData) => {
    try {
      const response = await axios.put(`/holidays/${holidayId}`, holidayData);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Failed to edit holiday");
    }
  };
  
  