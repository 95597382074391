
import axios from "axios";
import Swal from "sweetalert2";

export const adminAttendanceBulk = async (data) => {
 const storedUserData = localStorage.getItem("user");
 const userData = storedUserData ? JSON.parse(storedUserData) : null;
 const userId = userData.data._id; 

   // const userId = "6616d9b37e3162c123ef676e";

    try {
        const res = await axios.post("/adminAtendances/bulk-upload", {
            csv: data,
            userId,
        });

        if (res.status === 201) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Successfully uploaded bulk data",
            });
        }
    } catch (error) {
        if (error.response) {
            const {skippedRecords } = error.response.data;

            if (skippedRecords && skippedRecords.length > 0) {
                const skippedText = skippedRecords
                    .map(record => `Record Number: ${record.record_number}, Reason: ${record.reason}`)
                    .join('<br>');

                Swal.fire({
                    icon: "warning",
                    title: "The following records were skipped",
                   // text: `\n\n${skippedText}`,
                   html: `<p style="font-size: 17px; text-align: left; padding: 0px 27px;">\n\n${skippedText}</p>`,
                });
            }
        } else {
            console.error("An error occurred during the bulk upload:", error);
        }
    }
};


//bulk upload controller for create employee

export const createEmployeeBulk = async (data) => {
    
     try {
 
         const res =await axios.post(`/employees/bulk-upload`, {
             csv: data,
             //user: adminId
         });
         if (res.status === 201) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Successfully uploaded bulk data",
            });
        }
 
     } catch (error) {
 
        if (error.response) {
            const {skippedRecords } = error.response.data;

            if (skippedRecords && skippedRecords.length > 0) {
                const skippedText = skippedRecords
                    .map(record => `EPF No : ${record.employee}, Reason: ${record.reason}`)
                    .join('<br>');

                Swal.fire({
                    icon: "warning",
                    title: "The following records were skipped",
                   html: `<p style="font-size: 17px; text-align: left; padding: 0px 27px;">\n\n${skippedText}</p>`,
                });
            }
        } else {
            console.error("An error occurred during the bulk upload:", error);
        }
     }
 };
 
 export const createDeductionBulk = async (data) => {
    
    try {

        const res =await axios.post(`/deduction/bulk-upload`, {
            csv: data,
            //user: adminId
        });
        if (res.status === 201) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Successfully uploaded bulk data",
            });
        }
       

    } catch (error) {
        if (error.response) {
            const {skippedRecords } = error.response.data;

            if (skippedRecords && skippedRecords.length > 0) {
                const skippedText = skippedRecords
                    .map(record => `Record: ${record.recordNumber}, Reason: ${record.reason}`)
                    .join('<br>');

                Swal.fire({
                    icon: "warning",
                    title: "The following records were skipped",
                   html: `<p style="font-size: 17px; text-align: left; padding: 0px 27px;">\n\n${skippedText}</p>`,
                });
            }
        } else {
            console.error("An error occurred during the bulk upload:", error);
        }
    }
};



export const createLeavesBulk = async (data) => {
    
    try {

        const res =await axios.post(`/leaves-bulk-upload`, {
            csv: data,
            //user: adminId
        });
        if (res.status === 201) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Successfully uploaded bulk data",
            });
        }
             

    } catch (error) {
        console.log(error)
        if (error.response) {
            const {skippedRecords } = error.response.data;

            if (skippedRecords && skippedRecords.length > 0) {
                const skippedText = skippedRecords
                    .map(record => `Record: ${record.recordNumber}, Reason: ${record.reason}`)
                    .join('<br>');

                Swal.fire({
                    icon: "warning",
                    title: "The following records were skipped",
                   html: `<p style="font-size: 17px; text-align: left; padding: 0px 27px;">\n\n${skippedText}</p>`,
                });
            }
        } else {
            console.error("An error occurred during the bulk upload:", error);
        }
    }
};

export const createAllowanceBulk = async (data) => {
    
    try {

        const res =await axios.post(`/allowance-bulk-upload`, {
            csv: data,
            //user: adminId
        });
        if (res.status === 201) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Successfully uploaded bulk data",
            });
        }
       

    } catch (error) {
        if (error.response) {
            const {skippedRecords } = error.response.data;

            if (skippedRecords && skippedRecords.length > 0) {
                const skippedText = skippedRecords
                    .map(record => `Record: ${record.recordNumber}, Reason: ${record.reason}`)
                    .join('<br>');

                Swal.fire({
                    icon: "warning",
                    title: "The following records were skipped",
                   html: `<p style="font-size: 17px; text-align: left; padding: 0px 27px;">\n\n${skippedText}</p>`,
                });
            }
        } else {
            console.error("An error occurred during the bulk upload:", error);
        }
    }
};

export const leaveAllocationBulk = async (data) => {
    
    try {

        const res =await axios.post(`/allowance-bulk-upload`, {
            csv: data,
            //user: adminId
        });
        if (res.status === 201) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Successfully uploaded bulk data",
            });
        }
       

    } catch (error) {
        if (error.response) {
            const {skippedRecords } = error.response.data;

            if (skippedRecords && skippedRecords.length > 0) {
                const skippedText = skippedRecords
                    .map(record => `Record: ${record.recordNumber}, Reason: ${record.reason}`)
                    .join('<br>');

                Swal.fire({
                    icon: "warning",
                    title: "The following records were skipped",
                   html: `<p style="font-size: 17px; text-align: left; padding: 0px 27px;">\n\n${skippedText}</p>`,
                });
            }
        } else {
            console.error("An error occurred during the bulk upload:", error);
        }
    }
};