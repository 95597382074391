
import axios from "axios";
import Swal from 'sweetalert2';
const showWarning = (str) => {
    return Swal.fire({
        title: "Error",
        text: str,
        icon: "error",
    });
}


export const getAllAllowances = async () => {
    try {
        const response = await axios.get('/allowances/all');
        if (response.data.success) {
            return response.data.data;
        } else {
            console.error('Error fetching allowances:', response.data.message);
        }
    } catch (error) {
        console.error('API Error:', error.message);
    }
};


export const createEmployeeAllowance = async (selectedEmployee, selectedAllowanceType, amount) => {
    try {
        const response = await axios.post('/crete-allowance', {
            employee:selectedEmployee,
            allowanceType:selectedAllowanceType,
            amount:amount,
        });

        if (response.data.status === 'success') {                        
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Successfully saved allowance',
          });
            return response.data.data;
        } else {
             showWarning(response.data.message);
        }
    } catch (error) {
        showWarning(error.message);
    }
};

export const getAllEmployeeAllowances = async () => {
    try {
      const response = await axios.get('/all-allowance');
  
      if (response.data.status === 'success') {
        console.log('Employee Allowances:', response.data.data);
        return response.data.data; // Return data for further processing
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('API Error:', error.message);
    }
  };
  export const deleteEmployeeAllowance = async (id) => {
    try {
      const response = await axios.delete(`/delete-allowance/${id}`);
      console.log('Allowance deleted:', response.data);
      // Handle success, such as updating the UI
    } catch (error) {
      console.error('Error deleting allowance:', error.response.data);
      // Handle error
    }
  };
  
  export const getAllowanceById = async (id) => {
    try {
      const response = await axios.get(`/get-allowanceById/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching allowance by ID:", error);
      return null;
    }
  };

  export const updateEmployeeAllowance = async (selectedAllowance,selectedEmployee, selectedAllowanceType, amount) => {
    try {
      const response = await axios.put(`/update-allowanceById/${selectedAllowance}`, {
        employee:selectedEmployee,
        allowanceType:selectedAllowanceType,
        amount:amount,
      });
      
      if (response.data.status === 'success') {                        
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Successfully updated',
        });
          return response.data.data;
      } else {
           showWarning(response.data.message);
      }
    } catch (error) {
      showWarning("Error updating employee allowance")
      console.error('Error updating employee allowance:', error);
       // You can handle the error here or display it to the user
    }
  };

  export const fetchSearchAllowance = async ({name,epf_number,allowanceType,setLoading}) => {
    try {setLoading(true)
        const response = await axios.get(`/search-allowance`, {
            params: {
                name:name,
                epf_number:epf_number,
                allowanceType:allowanceType
  
            }, headers: {
                'Cache-Control': 'no-cache'
            }
        });
  
        //console.log('Payroll Records:', response.data.payrollRecords);
        setLoading(false) 
        console.log(response.data.data,"This is Response")
        return response.data.data.allowances;
        
    } catch (error) {
        
        Swal.fire({
            title: 'Error!',
            text: error.response ? error.response.data.message : 'An error occurred while fetching deductions.',
            icon: 'error',
            confirmButtonText: 'Try Again'
        });
    
     setLoading(false)
    }
  };
  
  