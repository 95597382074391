import React, { useEffect, useState } from 'react'
import { PlusCircle } from "feather-icons-react/build/IconComponents";
//import { Link } from 'react-router-dom'
//import Select from 'react-select'
import { uploadProfilePic, getRoles } from "../../../service/ApiServices";
import {fetchUserById,updateUserById  } from "../../../service/userService";
import propTypes from 'prop-types';
import Swal from "sweetalert2";
import {Modal} from "antd";
const EditUser = ({ open,userId,onClose}) => {

  //add profile pic
  const [image, setImage] = useState(null);

 
  const handleUploadClick = async () => {
    if (!image) {
      alert('Please select an image');
      return;
    }

    const userId = 'USER_ID'; // Replace with actual user ID
    const result = await uploadProfilePic(userId, image);

    if (result.success) {
      alert('Profile picture updated successfully');
    } else {
      alert(result.error);
    }
  };



  //end profile pic uploading



  //add user work start

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [, setRoleName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [description, setDescription] = useState("");
  useEffect(() => {
    const fetchRoles = async () => {
      const rolesData = await getRoles();
      setRoles(rolesData);
    };
    fetchRoles();
  }, []);

  const handleRoleChange = (e) => {
    const roleId = e.target.value;
    setSelectedRole(roleId);
    const role = roles.find((role) => role._id === roleId);
    // console.log(roleId, "ROLE-ID")
    if (role) {
      setRoleName(role.name);
    }
  };
  useEffect(() => {
            const fetchUserDetails = async (userId) => {
                try {
                    // Fetch user details using the user ID
                    const userData = await fetchUserById(userId);
                    console.log(userData,"DADADADAADADAD")
                    // Set the user details in the state
                    setName(userData.name);
                    setPhone(userData.phone);
                    setEmail(userData.email);
                    setSelectedRole(userData.role._id);
                    setPassword(userData.password);
                    setConfirmPassword(userData.password);
                   setImage(userData.profile_picture)
                   if (userData.profile_picture) {
                    const previewUrl = URL.createObjectURL(userData.profile_picture); // Create a preview URL
                    setImagePreview(previewUrl); // Set the preview URL
                  }
                } 
                catch (error) {
                    console.error('Error fetching user details:', error);
                }
                
            };
    
            if (userId) {
                console.log(userId,"USERRRRRRRR")
                fetchUserDetails(userId);
            }
        }, [userId]);

  const handleCreateUser = async (e) => {
    e.preventDefault();

    let errorMessage = "";

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      errorMessage = "Invalid email format";
    } else if (!password) {
      errorMessage = "Password cannot be empty";
    }

    if (errorMessage) {
      await Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("phone", phone);
      formData.append("email", email);
      formData.append("role", selectedRole);
      formData.append("password", password);
      formData.append("confirmPassword", confirmPassword);

      if (image && image instanceof File) {
        console.log(image, "IMAGUU");
        formData.append("profilePicture", image); // Append the profile picture file
      }
    
  console.log(formData,"FORM")
    await updateUserById(userId,formData);
    await onClose()
      handleReset()
      // Close the modal programmatically
      await Swal.fire({
        title: "Success",
        text: "User created successfully!",
        icon: "success",
      });
    //   await onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const storedUserData = localStorage.getItem("user");
  const storeData = JSON.parse(storedUserData);

   console.log(storeData, "ROLEE");

  //add user work end

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };


const [imagePreview, setImagePreview] = useState(null);
const [showPreview, setShowPreview] = useState(false);
const handleImageChange = (e) => {
  const file = e.target.files[0];
  setImage(file); // Store the file object
  if (file) {
    const previewUrl = URL.createObjectURL(file); // Create a preview URL
    setImagePreview(previewUrl); // Set the preview URL
  }
};
const handleImageClick = () => {
  setShowPreview(true); // Open the modal
};
const handleClosePreview = () => {
  setShowPreview(false); // Close the modal
};
useEffect(() => {
  return () => {
    if (imagePreview) {
      URL.revokeObjectURL(imagePreview);
    }
  };
}, [imagePreview]);
const handleReset = () => {
  setName("");
  setPhone("");
  setEmail("");
  setSelectedRole("");
  setPassword("");
  setConfirmPassword("");
  setImage(null); // Reset the image as well
  setImagePreview(null); // Reset image preview
};
const handleClose =async()=>{
await onClose()
handleReset()
}
  return (
    <div>
      {/* Add User */}
      <Modal zIndex={2000} title="Add User" footer={false} open={open} 
       onCancel={onClose}
       >
       
          <div className="row">
            <div className="col-lg-12">
              <div className="new-employee-field">
                <span>User profile</span>
                <div className="profile-pic-upload mb-2">
                  <div className="profile-pic">
                    {imagePreview||image ? (
                      <img
                        src={imagePreview||image}
                        alt="Profile"
                        style={{
                          width: "150px",
                          height: "118px",
                          borderRadius: "10%",
                          objectFit: "cover",
                        }}
                        onClick={handleImageClick}
                      />
                    ) : (
                      <span>
                        <PlusCircle className="plus-down-add" />
                        Profile Photo
                      </span>
                    )}
                  </div>
                  <div className="input-blocks mb-0">
                    <div className="image-upload mb-0">
                      <input 
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange} />
                      <div className="image-uploads"
                           onClick={handleUploadClick}
                      >
                        <h4>Change Image</h4>
                      </div>
                      {/* <button
                                 className="image-uploads"
                                onClick={handleUploadClick}>Change Image</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>User Name</label>
                <input
                    type="text"
                    value={name
                    }
                    className="form-control"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Phone</label>
                <input
                value={phone}
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Email</label>
                <input
                    value={email}
                    type="email"
                    className="form-control"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Role</label>
                <select
                    value={selectedRole}
                    onChange={handleRoleChange}
                    className="form-control"
                >
                  <option value="">Select Role</option>
                  {roles.map((role) => (
                      <option key={role._id} value={role._id}>
                        {role.roleName}
                      </option>

                  ))}
                </select>

              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Password</label>
                <div className="pass-group">
                  <input
                  value={password}
                      type={showPassword ? "text" : "password"}
                      className="pass-input"
                      placeholder="Enter your password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                  />
                  <span
                      className={`fas toggle-password ${showPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={handleTogglePassword}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Confirm Passworrd</label>
                <div className="pass-group">
                  <input
                  value={confirmPassword}
                      type={showConfirmPassword ? "text" : "password"}
                      className="pass-input"
                      placeholder="Enter your password"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                  />
                  <span
                      className={`fas toggle-password ${showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={handleToggleConfirmPassword}
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-lg-12">
                        <div className="mb-0 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />

                          <p>Maximum 600 Characters</p>
                        </div>
                      </div> */}
          </div>
          <div className="modal-footer-btn">
          <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
           <button
                        type="button"
                        className="btn btn-cancel"
                        
                        onClick={handleClose}
                    >
              Cancel
            </button>
            {/* <Link
                      to="/users"
                      type="button"
                      className="btn btn-submit"
                      onClick={handleCreateUser}
                      >
                        Submit
                      </Link> */}
                      <div>
              <button
                type="button"
                className="btn btn-reset me-2"
                onClick={handleReset}
              >
                Reset
              </button>
            <button
                type="submit"
                className="btn btn-submit"
                onClick={handleCreateUser}

            >
              Submit
            </button>
            </div>
            </div>
          </div>
        
      </Modal>

      <Modal
         zIndex={2000}
        title="Profile picture"
        open={showPreview}
        footer={null}
        onCancel={handleClosePreview}
        centered
      >
        <img
          src={imagePreview}
          alt="Preview"
          style={{ width: "100%", borderRadius: "10px" }}
        />
      </Modal>

      {/* /Add User */}
    </div>
  );
};

EditUser.propTypes = {
    userId: propTypes.string.isRequired,
    onClose: propTypes.func.isRequired,
    open: propTypes.bool
};
export default EditUser
