import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import {  Table, Select } from 'antd';
//const { RangePicker } = DatePicker;
import dayjs from 'dayjs';

import 'react-datepicker/dist/react-datepicker.css';

// import Chart from "react-apexcharts";
// import { Link } from "react-router-dom";
//import ApexCharts from 'react-apexcharts';
// getMonthlyCounts
import {
getNoOfEmployee, countLateAttendence_and_Leaves, findEmployeeByUserId,getLateAttendence
} from "../../service/dashboardSerice";
import ReactApexChart from "react-apexcharts";
//import { getAllInventories } from "../../service/inventoryService";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Calendar, } from "feather-icons-react/build/IconComponents";


const branchOptions = [
  { value: "kaduwela", label: "Kaduwela" },
  { value: "wattala", label: "Wattala" },
  { value: "negombo", label: "Negombo" },
  { value: "kiribathgoda", label: "Kiribathgoda" },
  { value: "kandana", label: "Kandana" }]


const Dashboard = () => {
  //const timeToday = dayjs();
  const lastDayOfCurrentMonth = dayjs().endOf('month').format('YYYY-MM-DD');
  const currentMonth = dayjs().month() + 1;
  // const [monthlySales, setMonthlySales] = useState([]);
  // const [monthlyPurchase, setMonthlyPurchase] = useState([]);
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  //const [quantity, setQuantity] = useState([])
  //const [productName, setProductName] = useState([])
 // const [startDate, setStartDate] = useState(null);
  //const [endDate, setEndDate] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState();
  const [noOfLeaveCount, setNoOfLeaveCount] = useState(0);
  const [noOfEmployee, setNoOfEmployee] = useState(0);
  const [countLate, setCountLate] = useState(0);
  const [tableData, setTableData] = useState([])
  const [loding,setLoading]=useState(false)
  //const [todayLeaveCount, setTodayLeaveCount] = useState(0);
  const [startDateFromRange, setStartDateFromRange] = useState(dayjs(new Date()).format("YYYY-MM-DD"))
  const [endDateFromRange, setEndDateFromRange] = useState(dayjs(new Date()).format("YYYY-MM-DD"))
  

//  const today = new Date();
//  const formattedDate = today.toISOString().split('T')[0];
  // setFilterDate
  useEffect(() => {
    // monthlySalesPurchase(selectedYear);
    findEmployeeById();


  }, []);

  const findEmployeeById = async () => {
    try {
      const countResponse = await findEmployeeByUserId();
      //setSelectedBranch(countResponse.data)
      console.log(countResponse)
      getNoOfEmployee_onTodate(selectedBranch)
      NoOfLeaveCountBydateandCountLate(startDateFromRange, endDateFromRange, selectedBranch)
    } catch (error) {
      console.log(error.message);
    }
  };

  const NoOfLeaveCountBydateandCountLate = async (startDate, endDate, branchName) => {
    try {
      setLoading(true)
      const countResponse = await countLateAttendence_and_Leaves(startDate, endDate, branchName,setNoOfLeaveCount);
      setLoading(false)
      const lateAttendanceCount=await getLateAttendence(startDate, endDate, branchName)
      console.log(lateAttendanceCount,"LATE Count")
      setCountLate(lateAttendanceCount.data.total);
      setNoOfLeaveCount(countResponse.data.allRecordsCount);
      setTableData(countResponse.data.data)
     // setTodayLeaveCount(countResponse.data.todayRecordsCount)



    } catch (error) {
      console.log(error.message);
    }
  };


  const getNoOfEmployee_onTodate = async (brnachName) => {
    setNoOfEmployee(null)
    try {
      const countResponse = await getNoOfEmployee(brnachName);

      setNoOfEmployee(countResponse.data);

    } catch (error) {
      console.log(error.message);
    }
  };
  // const monthlySalesPurchase = async (year) => {
  //   try {
  //     const response = await getMonthlyCounts(year);
  //     const salesData = Array(12).fill(0); // Initialize array with 12 zeroes
  //     const purchaseData = Array(12).fill(0); // Initialize array with 12 zeroes

  //     // Populate the sales and purchase arrays with data from the response
  //     Object.entries(response.data.sales || {}).forEach(([month, count]) => {
  //       salesData[month - 1] = count; // month - 1 because array is 0-indexed
  //     });

  //     Object.entries(response.data.purchases || {}).forEach(
  //       ([month, count]) => {
  //         purchaseData[month - 1] = count; // month - 1 because array is 0-indexed
  //       }
  //     );

  //     // setMonthlySales(salesData);
  //     // setMonthlyPurchase(purchaseData);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };



  // const handleYearChange = (year) => {
  //   setSelectedYear(year);
  // };
  // const validNoOfEmployeeLength = Array.isArray(noOfEmployee)
  // ? (noOfEmployee.length-noOfLeaveCount)
  // : 0;


  var donutChart = {
    chart: {
      height: 350,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    // colors: ['#4361ee', '#888ea8', '#e3e4eb', '#d3d3d3'],
    // series: [noOfEmployee?.length, noOfLeaveCount?.length],
    series: [noOfLeaveCount, countLate],
    labels: ["Leave Count", "Late Count"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  // Chart options and data
  // const chartOptions = {
  //   series: [
  //     {
  //       name: "Sales",
  //       data: monthlySales,
  //     },
  //     {
  //       name: "Purchase",
  //       data: monthlyPurchase,
  //     },
  //   ],
  //   colors: ["#28C76F", "#EA5455"],
  //   chart: {
  //     type: "bar",
  //     height: 350,
  //     zoom: {
  //       enabled: true,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       borderRadius: 4,
  //       columnWidth: "50%",
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   xaxis: {
  //     categories: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ], // Month names as categories
  //   },
  //   yaxis: {
  //     min: 0,
  //     max: Math.max(...monthlySales, ...monthlyPurchase) + 5, // Set Y-axis dynamically based on max value
  //     tickAmount: 5,
  //   },
  //   legend: {
  //     show: false,
  //   },
  //   fill: {
  //     opacity: 1,
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 480,
  //       options: {
  //         legend: {
  //           position: "bottom",
  //           offsetY: 0,
  //         },
  //       },
  //     },
  //   ],
  // };

  /////////// Start Inventory Chart///////////
  // useEffect(() => {
  //   const getInventories = async () => {
  //     try {
  //       const inventoryData = await getAllInventories();
  //       const currentDate = new Date();


  //       const nonExpiredData = inventoryData.filter(item => {
  //         const expireDate = new Date(item.expire_date);
  //         return expireDate >= currentDate;
  //       });


  //       const filteredData = nonExpiredData.filter(item => {
  //         const createdAt = new Date(item.createdAt);
  //         return (!startDate || createdAt >= new Date(startDate)) &&
  //           (!endDate || createdAt <= new Date(endDate));
  //       });

  //       const groupedData = filteredData.reduce((acc, item) => {
  //         const { product_id, stock_qty } = item;
  //         const productId = product_id._id;
  //         const productName = product_id.productName;

  //         if (!acc[productId]) {
  //           acc[productId] = { stock_qty: 0, productId, productName };
  //         }

  //         acc[productId].stock_qty += stock_qty;
  //         return acc;
  //       }, {});

  //       const groupedDataArr = Object.values(groupedData);

  //       setQuantity(groupedDataArr.map(item => item.stock_qty));
  //       setProductName(groupedDataArr.map(item => item.productName));
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };

  //   getInventories();
  // }, [startDate, endDate]);



  // const InventoryChart = () => {
  //   const options = {
  //     chart: {
  //       height: 350,
  //       type: 'bar',
  //       events: {
  //         click: function (chart, w, e) {
  //           console.log(chart, w, e);
  //         }
  //       }
  //     },
  //     colors: ['#008FFB'],
  //     plotOptions: {
  //       bar: {
  //         columnWidth: '10%',
  //         distributed: true,
  //       }
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     legend: {
  //       show: false
  //     },
  //     tooltip: {
  //       enabled: true,
  //       custom: function ({ dataPointIndex }) {
  //         const qty = quantity[dataPointIndex];
  //         const name = productName[dataPointIndex]
  //         return `<div class="arrow_box">
  //                   <span><strong>${name}</strong></span><br/>
  //                   <span>Available Quantity: <strong>${qty}</strong></span>
                    
  //                 </div>`;
  //       }
  //     },
  //     xaxis: {
  //       categories: productName,
  //       labels: {
  //         style: {

  //           fontSize: '14px',
  //         }
  //       }
  //     }
  //   };

  //   const stock = [
  //     {

  //       data: quantity
  //     }
  //   ];

  //   return (
  //     <div>
  //       <ApexCharts
  //         options={options}
  //         series={stock}
  //         type="bar"
  //         height={350}
  //       />
  //     </div>
  //   );
  // };

  // useEffect(() => {
  //   const getStartAndEndOfMonth = () => {
  //     const now = new Date();
  //     const start = new Date(now.getFullYear(), now.getMonth(), 1);
  //     const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  //     return { start, end };
  //   };

  //   const { start: currentMonthStart, end: currentMonthEnd } = getStartAndEndOfMonth();
  //   setStartDate(dayjs(currentMonthStart));
  //   setEndDate(dayjs(currentMonthEnd));
  // }, []);

  // const handleDateChange = (dates) => {
  //   setStartDate(dates[0]);
  //   setEndDate(dates[1]);
  // };

  const columns = [
    {
      title: "Emp Name",
      dataIndex: "employee",
       render: (employee) => employee?.name || "-"
    },
    {
      title: "EPF No",
      dataIndex: "employee",
      render: (employee) => employee?.epf_number || "-",
    },
    {
      title: "Branch",
      dataIndex: "employee",
      render: (employee) => employee?.branch || "-",
    },


  ];



  const handleSelectChangeBranch = (selectedOption) => {
    setNoOfEmployee(null)
    setNoOfLeaveCount(0)
    setCountLate(0)
   // setTodayLeaveCount(0)

    setSelectedBranch(selectedOption);

    getNoOfEmployee_onTodate(selectedOption)
    NoOfLeaveCountBydateandCountLate(startDateFromRange, endDateFromRange, selectedOption)
  };
  // const initialSettings = {
  //   endDate: new Date("2020-08-11T12:30:00.000Z"),
  //   ranges: {
  //     "Last 30 Days": [
  //       new Date("2020-07-12T04:57:17.076Z"),
  //       new Date("2020-08-10T04:57:17.076Z"),
  //     ],
  //     "Last 7 Days": [
  //       new Date("2020-08-04T04:57:17.076Z"),
  //       new Date("2020-08-10T04:57:17.076Z"),
  //     ],
  //     "Last Month": [
  //       new Date("2020-06-30T18:30:00.000Z"),
  //       new Date("2020-07-31T18:29:59.999Z"),
  //     ],
  //     "This Month": [
  //       new Date("2020-07-31T18:30:00.000Z"),
  //       new Date("2020-08-31T18:29:59.999Z"),
  //     ],
  //     Today: [
  //       new Date("2020-08-10T04:57:17.076Z"),
  //       new Date("2020-08-10T04:57:17.076Z"),
  //     ],
  //     Yesterday: [
  //       new Date("2020-08-09T04:57:17.076Z"),
  //       new Date("2020-08-09T04:57:17.076Z"),
  //     ],
  //   },
  //   startDate: new Date("2020-08-04T04:57:17.076Z"), // Set "Last 7 Days" as default
  //   timePicker: false,
  // };
  const getLastNDays = (n) => {
    const today = dayjs();
    return [
      today.subtract(n, 'day').startOf('day').toDate(),
      today.endOf('day').toDate()
    ];
  };
  
  const getStartOfMonth = (monthOffset = 0) => {
    const today = dayjs().add(monthOffset, 'month');
    return today.startOf('month').toDate();
  };
  
  const getEndOfMonth = (monthOffset = 0) => {
    const today = dayjs().add(monthOffset, 'month');
    return today.endOf('month').toDate();
  };
  
  const initialSettings = {
    endDate: dayjs().toDate(), // Set current date as the end date
    ranges: {
      "Last 30 Days": getLastNDays(30),
      "Last 7 Days": getLastNDays(7),
      "Last Month": [
        getStartOfMonth(-1), // Start of last month
        getEndOfMonth(-1),   // End of last month
      ],
      "This Month": [
        getStartOfMonth(),   // Start of this month
        getEndOfMonth(),     // End of this month
      ],
      "Today": [
        dayjs().startOf('day').toDate(), // Start of today
        dayjs().endOf('day').toDate(),   // End of today
      ],
      "Yesterday": [
        dayjs().subtract(1, 'day').startOf('day').toDate(),
        dayjs().subtract(1, 'day').endOf('day').toDate(),
      ],
    },
    startDate: dayjs().startOf('day').toDate(), // Set "Last 7 Days" as default start date
    timePicker: false,
  };

  const handleApply = (event, picker) => {
    setStartDateFromRange(picker.startDate.format("YYYY-MM-DD"))
    setEndDateFromRange(picker.endDate.format("YYYY-MM-DD"))
    const formattedStartDate = picker.startDate.format("YYYY-MM-DD");
    const formattedEndDate = picker.endDate.format("YYYY-MM-DD");
    //console.log(picker.endDate.format("YYYY-MM-DD"),"This is the picker")
    NoOfLeaveCountBydateandCountLate(formattedStartDate, formattedEndDate, selectedBranch);

  };


  const storedUserData = localStorage.getItem('user');
  console.log("USER", storedUserData)
  const userData = JSON.parse(storedUserData)
  /////////End  Inventory chart/////////
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="welcome d-lg-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center welcome-text">
              <h3 className="d-flex align-items-center">
                <ImageWithBasePath src="assets/img/icons/hi.svg" alt="img" />
                &nbsp;Hi {userData?.data.name},
              </h3>
              &nbsp;
              <h6>here&apos;s what&apos;s happening with you today.</h6>
            </div>
            <div className="d-flex align-items-center">
              <div className="position-relative daterange-wraper me-2">
                <div className="input-groupicon calender-input">
                  <DateRangePicker initialSettings={initialSettings} onApply={handleApply}>
                    <input
                      className="form-control col-4 input-range"
                      type="text"
                    // style={{ border: "none" }}
                    />
                  </DateRangePicker>
                </div>
                <Calendar className="feather-14" />
              </div>
              
              <div className="position-relative daterange-wraper me-4 ms-2">
                <Select
                  value={
                    branchOptions.value
                  }

                  options={branchOptions}
                  className="select"
                  onChange={(selectedOption) => handleSelectChangeBranch(selectedOption)}

                  style={{ width: '100%' }}
                  placeholder="Select branch" // Optional placeholder text
                />
              </div>


            </div>
          </div>
          {/*<div className="row">*/}
          {/*  <div className="col-4">*/}


          {/*    <div className="input-blocks">*/}
          {/*      <label className="form-label">Start Date</label>*/}
          {/*      <div className="input-groupicon calender-input">*/}
          {/*        <DatePicker*/}
          {/*            selected={filterDate}*/}
          {/*            onChange={handleDate}*/}
          {/*            type="date"*/}
          {/*            className="filterdatepicker"*/}
          {/*            dateFormat="dd-MM-yyyy"*/}

          {/*        />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*  <div className="col-4">*/}


          {/*    <div className="input-blocks">*/}
          {/*      <label className="form-label">Start Date</label>*/}
          {/*      <div className="input-groupicon calender-input">*/}
          {/*        <Select*/}
          {/*            value={*/}
          {/*              branchOptions.value*/}
          {/*            }*/}

          {/*            options={branchOptions}*/}
          {/*            className="select"*/}
          {/*            onChange={(selectedOption) => handleSelectChangeBranch(selectedOption)}*/}

          {/*            style={{width: '100%'}}*/}
          {/*            placeholder="Select branch" // Optional placeholder text*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}


          <div className="row">
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget w-100">
                <div className="dash-widgetimg">
                  <span>
                    <i className="fa-solid fa-users"></i>
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <CountUp

                      end={noOfEmployee?.length}
                      duration={3}>
                      +
                    </CountUp>
                  </h5>
                  <h6>No of Employees</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget dash1 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <i className="fa-solid fa-gift"></i>

                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <CountUp
                      start={0}
                      end={noOfLeaveCount}
                      duration={3} // Duration in seconds

                    />
                  </h5>
                  <h6>No of Leaves</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget dash2 w-100">
                <div className="dash-widgetimg">
                  <span>

                    <i className="fa-solid fa-clipboard-user"></i>

                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <CountUp
                      start={0}
                      end={countLate}
                      duration={3} // Duration in seconds
                    />
                  </h5>
                  <h6>No of Late Attendence </h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget dash3 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <i className="fa-regular fa-calendar-days"></i>

                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <CountUp
                      start={0}
                      end={currentMonth}
                      duration={3} // Duration in seconds
                    />
                  </h5>
                  <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><h6>Next pay date</h6>
                    <h6 style={{ fontWeight: 'bold' }}>{lastDayOfCurrentMonth}</h6></span>

                </div>
              </div>
            </div>
          </div>

          {/* Button trigger modal */}

          <div className="row">
            <div className="col-xl-6 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h5 className="card-title" style={{ margin: 0 }}>Leave Employees</h5>
                </div>

                <div className="card-body">
                  <Table columns={columns}
                   dataSource={tableData} 
                   pagination={{ pageSize: 5 }}
                   loading={loding}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Attendence Chart</h5>
                </div>
                <div className="card-body">

                  <div id="donut-chart" />
                  <ReactApexChart
                    options={donutChart}
                    series={donutChart.series}
                    type="donut"
                    height={400}
                  />
                </div>
              </div>
            </div>
          </div>


          {/* <div className="row">
            <div className="col-xl-6 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Purchase &amp; Sales</h5>
                  <div className="graph-sets">
                    <ul className="mb-0">
                      <li>
                        <span>Sales</span>
                      </li>
                      <li>
                        <span>Purchase</span>
                      </li>
                    </ul>
                    <div className="dropdown dropdown-wraper">
                      <select
                        className="form-select"
                        value={selectedYear}
                        onChange={(e) =>
                          handleYearChange(Number(e.target.value))
                        }
                      >
                        {[
                          new Date().getFullYear(),
                          new Date().getFullYear() - 1,
                          new Date().getFullYear() - 2,
                          new Date().getFullYear() - 3,
                        ].map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {[
                          new Date().getFullYear(),
                          new Date().getFullYear() - 1,
                          new Date().getFullYear() - 2,
                          new Date().getFullYear() - 3,
                        ].map((year) => (
                          <li key={year}>
                            <Link
                              to="#"
                              className="dropdown-item"
                              onClick={() => handleYearChange(year)}
                            >
                              {year}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="sales_charts" />
                  <Chart
                    options={chartOptions}
                    series={chartOptions.series}
                    type="bar"
                    height={320}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Donut Chart</h5>
                </div>
                <div className="card-body">

                  <div id="donut-chart" />
                  <ReactApexChart
                    options={donutChart}
                    series={donutChart.series}
                    type="donut"
                    height={350}
                  />
                </div>
              </div>
            </div>
          </div> */}


          {/* /////// Inventory Chart Dilshan ////////// */}
          {/* <div className="row">
            <div className="col-xl-12">
              <div className="card flex-fill">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Inventory Chart</h5>
                  <div className="graph-sets">
                    <ul className="mb-0">
                      <div style={{ display: 'inline-flex', alignItems: 'center', marginRight: '20px' }}>
                        <span style={{
                          width: '10px',
                          height: '10px',
                          backgroundColor: '#008FFB',
                          borderRadius: '50%',
                          display: 'inline-block',
                          marginRight: '5px'
                        }}></span>
                        <span>Available Stock Level</span>
                      </div>
                    </ul>

                    <div className="dropdown dropdown-wraper">
                      <RangePicker
                        value={[startDate, endDate]}
                        onChange={handleDateChange}
                        defaultValue={[dayjs(startDate), dayjs(endDate)]}
                        format="YYYY-MM-DD"
                      />
                    </div>

                  </div>
                </div>
                <div className="card-body">
                  <div id="sales_charts" />

                  <InventoryChart />
                </div>
              </div>
            </div>

          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
