import React, {useEffect, useState} from 'react';
import {Modal, TimePicker, DatePicker, Select, Badge, Spin, Popconfirm, Table} from 'antd';
import {PlusCircle, Upload} from 'react-feather';
import {Link} from 'react-router-dom';
import dayjs from 'dayjs';
import {
    createAttendanceMethod,
    deleteAdminAttendance,
        getAllEmployeesWithoutPaginationMethod,
   // getAllShiftsMethod,
    getAllAdmintAttendancesWithoutPagination
} from '../../service/ApiServices';
import Swal from 'sweetalert2';
import BulkUploadModal from '../../core/modals/hrm/bulkupload-admin.jsx';
import {API_BASE_URL} from "../../../src/environment";
import { Input } from 'antd';
//pdf generte import start
import jsPDF from 'jspdf';
//import html2canvas from 'html2canvas';
//import jsPDF from 'jspdf';
import 'jspdf-autotable';
//pdf generte import end
//import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';

//const {RangePicker} = TimePicker;
import okilogo from "../../assets/OKI-logo.png"
import EditAttendanceAdminModal from '../../core/modals/hrm/editAttendanceNew.jsx';
import axios from "axios";
import moment from "moment";
import {getAllDepartments as allDepartments} from "../../service/departmentService"
const Designation = () => {


    const startOfCurrentMonth = dayjs().startOf('month');
    const endOfCurrentMonth = dayjs().endOf('month');
    const [selectedRange] = useState([
        startOfCurrentMonth,
        endOfCurrentMonth
    ]);
    const [timeRangeForFetch, setTimeRangeForFetch] = useState(null)
    const [hours] = useState(null);
    const [isDelete, setIsDelete] = useState(false);
    const [minutes] = useState(null);
    const [workingHours, setWorkingHours] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    //const [timeRange, setTimeRange] = useState(null);
    const [date, setDate] = useState(null);
    const [employee, setEmployee] = useState(null);
   // const [shift, setShift] = useState(null)
    const [search,] = useState('');
    const [employees, setEmployees] = useState([])
    //const [shifts, setShifts] = useState([])
    const [loading, setLoading] = useState(false)
    const [loading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [success, setSuccess] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false);
    const [shouldReloadData, setShouldReloadData] = useState(false);
    const [id, setId] = useState('');
    const [recordDate, setRecordDate] = useState(null);
    const [recordEmpId, setRecordEmpId] = useState(null);
    const [employeeId, setEmployeeId] = useState('');
    const [branchId, setBranchId] = useState('');
    const [time, setTime] = useState(null);
    const [reason, setReason] = useState('');

    const [empType,setEmpType] = useState(null)
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);

    const [employeeName, setEmployeeName] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const [attendanceData, setAttendanceData] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0
    });
    const [pdfData, setPdfData] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0
    });
    const [hasFetched, setHasFetched] = useState(false);
    const employeeTypes = [
        {value: "", label: "Choose"},
        {value: "Academic", label: "Academic"},
        {value: "Non Academic", label: "Non Academic"},
    ]

    const handleReloadData = () => {
        setShouldReloadData(!shouldReloadData);
    };
//Function generate to excel
    const handleTimeChange = (time) => {
        const formatTime= time ? time.format("HH:mm:ss") : null
        setTime(formatTime)
    };
    const handleReasonChange = (e) => {
        setReason(e.target.value); // Update state with the new value
        console.log('Reason:', e.target.value); // Optional: Log the updated value
    };

    const showWarning = (str) => {
        return Swal.fire({
            title: "Error",
            text: str,
            icon: "error",
        });
    }

    const handleExcelExport = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Attendance Report');

        // Define cell styles with borders
        const borderStyle = {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'}
        };

        // Define columns and add header
        worksheet.columns = [
            {header: 'Emp Name', key: 'empName', width: 20},
            {header: 'Emp Id', key: 'empId', width: 15},
            {header: 'Branch', key: 'branch', width: 15},
            {header: 'Date', key: 'date', width: 15},
            {header: 'Clock In', key: 'clockIn', width: 15},
            {header: 'Clock Out', key: 'clockOut', width: 15},
            {header: 'Total Hours', key: 'totalHours', width: 15}
        ];
    
        // Add data rows
        pdfData.data.forEach(({employee, attendanceDate, earliestClockIn, latestClockOut, totalHours}) => {
            worksheet.addRow({
                empName: `${employee.first_name} ${employee.last_name}`,
                empId: employee.epf_number,
                branch:employee.branch,
                date: new Date(attendanceDate).toLocaleDateString(),
                clockIn: earliestClockIn ? earliestClockIn : 'N/A',
                clockOut: latestClockOut ? latestClockOut : 'N/A',
                totalHours: totalHours
            });
        });

        // Apply border styles to all cells
        worksheet.eachRow({includeEmpty: true}, (row) => {
            row.eachCell({includeEmpty: true}, (cell) => {
                cell.border = borderStyle;
            });
        });

        // Save the workbook
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'attendance_report.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };

    useEffect(() => {
        if (selectedRange[0] && selectedRange[1] && !hasFetched) {
            getAllAdmintAttendancesWithoutPagination({
                setPdfData,
                setLoading: setLoading3,
                params: {page: pdfData.page, limit: pdfData.limit},
                search,
                startDate: selectedRange[0].format("YYYY-MM-DD"),
                endDate: selectedRange[1].format("YYYY-MM-DD"),
                workingHours: timeRangeForFetch ? timeRangeForFetch : "undefined"
            });
            setHasFetched(true)
            console.log(loading3)
        }

    }), [selectedRange[0], selectedRange[1]]

    const  handleChangeDepartment=(value)=>{
        setSelectedDepartment(value)
      }
    useEffect(() => {
        const fetchLeaveTypes = async () => {
          try {
          const department= await allDepartments({ search:"" })
            const dptOptions=department.map(item => ({
              value: item.departmentName,
              label: item.departmentName
            }));
            setDepartmentOptions(dptOptions)
          } catch (error) {
            console.error("Error in fetching Service Names", error);
            
          } finally {
            setLoading(false);
          }
        };
        fetchLeaveTypes();
      }, []);

const handlePdfExport = async () => {
    //  await getAllAdmintAttendancesWithoutPagination({
    //     setPdfData,
    //     setLoading: setLoading2,
    //     params: { page: pdfData.page, limit: pdfData.limit },
    //     search,
    //     startDate: selectedRange[0].format("YYYY-MM-DD"),
    //     endDate: selectedRange[1].format("YYYY-MM-DD"),
    //     workingHours: timeRangeForFetch ? timeRangeForFetch : "undefined"
    // });

    const startdate= selectedRange[0].format('YYYY-MM-DD');
    const enddate=selectedRange[1].format('YYYY-MM-DD');
    const img=okilogo
    // Filter out unwanted columns
    const filteredData = attendanceData.data.map(({ employee, date, clockIn,clockOut,workedHours }) => ({
        'Emp Name': `${employee.first_name} ${employee.last_name}`,
        'Emp Id': employee.employee_id,
        'Date': new Date(date).toLocaleDateString(),
        'Clock In': clockIn ? clockIn : 'N/A',
        'Clock Out': clockOut ? clockOut: 'N/A', // Handle cases where shift info might be missing
        'Total Hours': workedHours
    }));

    const doc = new jsPDF();
    doc.setTextColor(0,0,0); // Blue color
    doc.setFontSize(16);
    doc.text('OKI International', 15, 13);
    doc.setFontSize(14);
    doc.setTextColor(138, 132, 125);
    doc.text(`Attendance Report`, 15, 23);
   doc.text(`Date : ${startdate} - ${enddate}`, 15, 30);
   doc.addImage(img, 'PNG', 170, 8, 22, 25);
    doc.autoTable({
        head: [['Emp Name', 'Emp Id', 'Date','Clock In', 'Clock Out', 'Total Hours']],
        body: filteredData.map(data => [
            data['Emp Name'],
            data['Emp Id'],
            data['Date'],
            data['Shift'],
            data['Clock In'],
            data['Clock Out'],
            data['Total Hours']
        ]),
        startY: 35,
        theme: 'striped',
        headStyles: {
            fillColor: [138, 132, 125],
        }
    });

        setTimeout(() => {
            doc.save('attendance_report.pdf');
        }, 1000);
        // // Save the PDF
        // doc.save('attendance_report.pdf');
    };


////Function to generate PDF end


    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const id1 = userData?.data?.uid; //
    const [userRightssub, setUserRightssub] = useState([]);
    useEffect(() => {
        const fetchUserRightssub = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/userright/${id1}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch user rights");
                }
                const data = await response.json();
                console.log("Fetched User Sub Data:", data.data);
                setUserRightssub(data.data || []); // Ensure it's always an array
            } catch (error) {
                console.error("Error fetching data:", error);
                setUserRightssub([]); // Set to empty array in case of error
            }
        };

        fetchUserRightssub(); // Fetch the data on component mount
    }, [id]);
    const hasJobRightWithAdd = userRightssub.some(right => right.right === 'attendance-employee' && right.adds === 1);
    const hasJobRightWithedit = userRightssub.some(right => right.right === 'attendance-employee' && right.updates === 1);
// const hasJobRightWithview = userRightssub.some(right => right.right === 'attendance-employee' && right.viewes === 1);
    const hasJobRightWithedel = userRightssub.some(right => right.right === 'attendance-employee' && right.deletes === 1);
    const hasJobRightWithupload = userRightssub.some(right => right.right === 'Shifts' && right.fupload === 1);


    useEffect(() => {
        const initialCalls = async () => {
            getAllEmployeesWithoutPaginationMethod({setEmployeeData: setEmployees})
            //getAllShiftsMethod({setShiftData: setShifts})
        }
        initialCalls()
    }, [shouldReloadData])

    useEffect(() => {
        if (success) {
            handleCancel();
            setId(null)
            showSuccess("Successfully saved attendance!")

        }
        if (isDelete) {
            showSuccess("Successfully deleted attendance!")

        }
    }, [success, isDelete, shouldReloadData])


    useEffect(() => {
        const handler = setTimeout(async () => {


        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [search, selectedRange, timeRangeForFetch, shouldReloadData]);

    const formatDateToDDMMYYYY = (inputDate) => {
        // If inputDate is already a Date, use it; otherwise, parse it
        const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
    
        // Validate if the conversion was successful
        if (isNaN(date.getTime())) {
            throw new Error('Invalid date format');
        }
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    const handleOk = async () => {
        if (!time || !date || !employee) {
            showWarning("Please fill all fields")
            return;
        }
        const formatDate=formatDateToDDMMYYYY(date);
        //const formattedTimeRange = timeRange.map(time => dayjs(time).format('HH:mm:ss'));
        const submitData = {
            employee: employee,
            time: time,
            //workingHours,
            date:formatDate,
            reason
           // shift: shift,
           // id
        }
        console.log(submitData)
        await createAttendanceMethod({
            data: submitData,
            setLoading,
            setSuccess
        })
        handleReloadData()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsEdit(false);
        resetFields();
    };

    const resetFields = () => {
       // setTimeRange(null);
        setDate(null);
        setEmployee("");
        setWorkingHours(null);
        setTime(null)
        setReason("")
        //setShift("")
    };

    // const handleTimeRangeChange = (value) => {
    //     setTimeRange(value);
    //     if (value) {
    //         const [start, end] = value;
    //         const duration = dayjs(end).diff(dayjs(start), 'minute');
    //         const hours = Math.floor(duration / 60);
    //         const minutes = duration % 60;
    //         setWorkingHours(`${hours}h ${minutes}m`);
    //     } else {
    //         setWorkingHours(null);
    //     }
    // };

    const handleDateChange = (value) => {
        setDate(value ? dayjs(value).format('YYYY-MM-DD') : null);
    };

    const handleEmployeeChange = (value) => {
        setEmployee(value);
    };

    // const handleShiftChange = (value) => {
    //     setShift(value);
    // };

    const showSuccess = (str) => {
        return Swal.fire({
            title: "Good job!",
            text: str,
            icon: "success"
        });
    }

    const columns = [
        {
            title: 'Emp Name',
            key: 'name',
            render: (text, record) => `${record.employee.first_name} ${record.employee.last_name}`,
        },
        {
            title: 'Emp Id',
            dataIndex: ['employee', 'epf_number'],
            key: 'employee.epf_number',
        },
        {
            title: 'Branch',
            dataIndex: ['employee', 'branch'],
            key: 'employee.branch',
        },
        {
            title: 'Date',
            dataIndex: 'attendanceDate',
            key: 'date',
            render: (text, record) => `${new Date(record.attendanceDate).toLocaleDateString()}`,
        },
        {
            title: 'Day',
            dataIndex: 'dayOfWeek',
            key: 'dayOfWeek',
        },
        {
            title: 'Clock In',
            dataIndex: 'earliestClockIn',
            key: 'clockIn',
        },
        {
            title: 'Clock Out',
            dataIndex: 'latestClockOut',
            key: 'clockOut',

        },
        {
            title: 'Total Hours',
            dataIndex: 'totalHours',
            key: 'workingHours',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <td className="action-table-data">
                    <Link className="me-2 p-2" onClick={() => {
                        setIsView(true)
                        setIsEditModalOpen(true)
                        setRecordEmpId(record.employee.epf_number)
                        setRecordDate(record.attendanceDate)
                        setId(record._id)
                       // console.log(record.employee.epf_number)
                       // console.log(record)
                    }}>
                        <i data-feather="download" className="feather-eye"></i>
                    </Link>
                    {!hasJobRightWithedit && (
                        <Link className="me-2 p-2" onClick={() => {
                            setRecordEmpId(record.employee.epf_number)
                            setRecordDate(record.attendanceDate)
                            setId(record._id)
                            setIsEditModalOpen(true)
                        }}>
                            <i data-feather="download" className="feather-edit"></i>
                        </Link>
                    )} 
                    {hasJobRightWithedel && (
                        <Popconfirm
                            title="Delete the Attendance"
                            description="Are you sure to disable this Attendance?"
                            onConfirm={() => {
                                deleteAdminAttendance({
                                    id: record._id,
                                    setIsDelete
                                })
                            }}
                            onCancel={() => {
                                console.log("c")
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Link className="confirm-text p-2">
                                <i data-feather="x-circle" className="feather-x-circle"></i>
                            </Link>
                        </Popconfirm>
                    )}
                </td>
            )
        }
    ];
//

    const formatWorkHours = () => {
        if (hours !== null && minutes !== null) {
            return `${hours}h ${minutes}m`;
        }
        return null;
    };

    useEffect(() => {
        setTimeRangeForFetch(formatWorkHours())
    }, [minutes, hours])

    const searchAttendance = async (params = {}) => {
        const queryParams = {
            page: params.pagination?.current || pagination.current,
            limit: params.pagination?.pageSize || pagination.pageSize,
            ...params.filters,
        };

        if (employeeId) queryParams.epf_number = employeeId;
        if (branchId) queryParams.branchId = branchId;

            queryParams.startDate = startDate
            queryParams.endDate =endDate

        if (employeeName) queryParams.employeeName = employeeName;
        if (empType) queryParams.employee_type = empType;
        if (selectedDepartment) queryParams.departmentName = selectedDepartment;


        let response = await axios.get('/adminAtendances-search',{params: queryParams});

        setAttendanceData(response.data);

        setPagination({
            ...pagination,
            current: params.pagination?.current || pagination.current,
            pageSize: params.pagination?.pageSize || pagination.pageSize,
            total: response.data.totalResults,
        });

        const queryParamsR = {
            ...params.filters,
        };

        if (employeeId) queryParamsR.epf_number = employeeId;
        if (branchId) queryParamsR.branchId = branchId;

        queryParamsR.startDate = startDate
        queryParamsR.endDate =endDate

        if (employeeName) queryParamsR.employeeName = employeeName;
        if (empType) queryParamsR.employee_type = empType;
        if (selectedDepartment) queryParamsR.departmentName = selectedDepartment;

        let responseReportresponseReport = await axios.get('/adminAtendances-search-report',{params: queryParamsR});
        setPdfData(responseReportresponseReport.data)

    };

    useEffect(() => {
        searchAttendance({pagination});

    },[]);

    const handleTableChange = (newPagination) => {
        searchAttendance({ pagination: newPagination });
    };
    const handleSearch = async () => {

        console.log("Search")
        setPagination({...pagination, current: 1}); // Reset to first page
        await searchAttendance({pagination: {...pagination, current: 1}});
    };
    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Attendance</h4>
                            <h6>Manage your Attendances</h6>
                        </div>
                    </div>
                    <div className="wordset">
                        <ul>
                            <li>
                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                                    //</li> onClick={generatePDF}
                                   onClick={handlePdfExport}
                                >
                                    <img src="assets/img/icons/pdf.svg" alt="pdf"/>
                                </a>
                            </li>
                            <li>
                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                                   onClick={handleExcelExport}>
                                    <img src="assets/img/icons/excel.svg" alt="excel"/>
                                </a>
                            </li>
                            <li>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                                    <img src="assets/img/icons/printer.svg" alt="print"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="page-btn">

                        {!hasJobRightWithupload && (

                            <a className="btn btn-added" onClick={() => setIsBulkUploadModalOpen(true)}>
                                <Upload className="me-2"/>
                                Bulk upload
                            </a>
                        )}


                    </div>
                    <div className="page-btn">

                        {!hasJobRightWithAdd && (

                            <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
                                <PlusCircle className="me-2"/>
                                Add New Attendance
                            </a>
                        )}


                    </div>
                </div>
                <div className="card shadow-sm border-0 p-3">
                    <div className="row gx-3 gy-3">

                            <div className="col-2">
                                <label className="form-label">EPF No</label>
                                <input type="text" className="form-control" onChange={(e) => setEmployeeId(e.target.value)}/>
                            </div>
                            <div className="col-3">
                                <label className="form-label">Employee Name</label>
                                <input type="text" className="form-control" onChange={(e) => setEmployeeName(e.target.value)}/>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="start-date" className="form-label">Start Date</label>
                                <input
                                    type="date"
                                    id="start-date"
                                    className="form-control"
                                    onChange={(e) => setStartDate(e.target.value)}
                                    // value={startDate}
                                    // onChange={handleStartDateChange}
                                />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="end-date" className="form-label">End Date</label>
                                <input
                                    type="date"
                                    id="end-date"
                                    className="form-control"
                                    onChange={(e) => setEndDate(e.target.value)}
                                    // value={endDate}
                                    // onChange={handleEndDateChange}
                                    min={startDate} // Sets the minimum selectable end date to the start date
                                />
                            </div>
                            <div className="col-3">
                                <label className="form-label">Branch</label>
                                <select className="form-select" id="autoSizingSelect" onChange={(e) => setBranchId(e.target.value)}>
                                    <option value="">Choose...</option>
                                    <option value={"OKI - Kaduwela"}>OKI - Kaduwela</option>
                                    <option value={"OKI - Negombo"}>OKI - Negombo</option>
                                    <option value={"OKI - Kandana"}>OKI - Kandana</option>
                                    <option value={"OKI - Kiribathgoda"}>OKI - Kiribathgoda</option>
                                    <option value={"OKI - Wattala"}>OKI - Wattala</option>
                                    <option value={"OKI - Delgoda"}>OKI - Delgoda</option>
                                </select>
                            </div>

                        <div className="col-12">
                            <div className="d-flex align-items-center">
                                <div className="col-lg-2">
                                    <div className="input-blocks">
                                        <label>Employee Type</label>
                                         <Select
                                            value={empType}
                                            onChange={setEmpType}
                                            className="select"
                                            options={employeeTypes}
                                            placeholder="Select Employee Type"
                                            allowClear
                                            style={{ width: "100%", height: "40px" }}
                                        />
                                       
                                    </div>
                                </div>
                                <div className="col-lg-2 " style={{ marginLeft:20 }}>
                                    <div className="input-blocks">
                                        <label>Department</label>
                                        <Select
                                             value={selectedDepartment}
                                             options={departmentOptions}
                                             className="select"
                                             placeholder="Select Department"
                                             onChange={handleChangeDepartment}
                                             allowClear
                                             style={{ width: "100%", height: "40px" }}
                                        />
                                    </div>
                                </div>
                                <div className="ml-10" style={{ marginLeft:20 }}>
                                    <button
                                        onClick={handleSearch}
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">

                        </div>
                        <div className="table-responsive">
                            <Table
                                id="table-to-pdf"
                                columns={columns}
                                dataSource={attendanceData.data}
                                rowKey={(record) => record._id}
                                pagination={{
                                    current: pagination.current,
                                    pageSize: pagination.pageSize,
                                    total: pagination.total,
                                }}
                                loading={loading2}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                zIndex={200000}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                closable={false}
            >
                <div className="modal-content">
                    <div className="page-wrapper-new p-0">
                        <div className="content">
                            <div className="modal-header border-0 custom-modal-header">
                                <div className="page-title">
                                    <h4>{isEdit ? "Edit" : "Add"} Attendance</h4>
                                    <p className="mb-0">Fill in the required fields to {isEdit ? "edit" : "create"} an
                                        Attendance</p>
                                </div>
                            </div>
                            <div className="modal-body custom-modal-body">
                                <div className="mb-3">
                                    <label className="form-label">Time</label>
                                    <TimePicker
                                        size="large"
                                        value={time ? moment(time, 'HH:mm') : null}
                                        onChange={handleTimeChange}
                                        format="HH:mm"
                                        style={{ width: '100%' }}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Date</label>
                                    <DatePicker
                                        size="large"
                                        onChange={handleDateChange}
                                        format="YYYY-MM-DD"
                                        value={date ? dayjs(date, 'YYYY-MM-DD') : null}
                                        style={{width: '100%'}}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Select Employee</label>
                                    <Select
                                        size="large"
                                        showSearch
                                        placeholder="Select an employee"
                                        onChange={handleEmployeeChange}
                                        value={employees?.find(option => option.value === employee)}
                                        options={employees}
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                          }
                                          allowClear
                                        style={{width: '100%'}}

                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Reason</label>
                                    <Input.TextArea
                                        size="large"
                                        placeholder="Enter the reason"
                                        style={{ width: '100%' }}
                                        value={reason} // Bind state to TextArea
                                        onChange={handleReasonChange} // Handle input change
                                    />
                                </div>

                                {workingHours && (
                                    <div className="mb-3">
                                        <Badge color='geekblue' text={`Working Hours: ${workingHours}`}/>
                                    </div>
                                )}
                                <div className="" style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                    <button
                                        type="button"
                                        className="btn btn-cancel"
                                        onClick={() => {
                                            handleCancel();
                                        }}
                                    >
                                        Close
                                    </button>
                                    <div className="">
                                        <button
                                            type="button"
                                            className="btn btn-reset me-2"
                                            onClick={resetFields}
                                        >
                                            Reset
                                        </button>
                                        <button type='button' className="btn btn-submit ml-0" onClick={handleOk}
                                                disabled={loading}>
                                            {loading ? <Spin size='small'/> : "Save"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
                zIndex={1050}
                open={isEditModalOpen}
                onOk={() => setIsEditModalOpen(false)}
                onCancel={() => setIsEditModalOpen(false)}
                footer={null}
                maskClosable={false}
                closable={false}
                width={600}
            >
                <EditAttendanceAdminModal
                    handleCancel={() => {
                        setIsEditModalOpen(false)
                        setIsView(false)
                    }}
                    employeeId={recordEmpId}
                    date={recordDate}
                    isView={isView}
                    onChange={searchAttendance}
                />
            </Modal>

            <BulkUploadModal
                isVisible={isBulkUploadModalOpen}
                handleCancel={() => setIsBulkUploadModalOpen(false)}
                onUploadData={handleReloadData}
            />
        </div>
    );
};

export default Designation;


