import axios from 'axios';
import Swal from 'sweetalert2';
// Get user by ID
export const getUserById = async (userId) => {
  try {
    const response = await axios.get(`/user/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to fetch user details");
  }
};



  
const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};

// Function to validate phone number format
const isValidPhoneNumber = (phone) => {
    const phonePattern = /^\+?[0-9]{10,15}$/; // Adjust regex based on your phone number format
    return phonePattern.test(phone);
};

export const updateUser = async (userId, updatedData) => {
    // Check if the email is valid
    if (updatedData.email && !isValidEmail(updatedData.email)) {
        Swal.fire({
            title: 'Invalid Email!',
            text: 'Please enter a valid email address.',
            icon: 'error',
            confirmButtonText: 'OK'
        });
        return; // Exit the function if the email is invalid
    }

    // Check if the phone number is valid
    if (updatedData.phone && !isValidPhoneNumber(updatedData.phone)) {
        Swal.fire({
            title: 'Invalid Phone Number!',
            text: 'Please enter a valid phone number.',
            icon: 'error',
            confirmButtonText: 'OK'
        });
        return; // Exit the function if the phone number is invalid
    }

    try {
        const response = await axios.put('/user/update', {
            userId,
            ...updatedData
        });
      
        
        console.log('User updated successfully:', response.data.data.user);

        // Show success SweetAlert
        Swal.fire({
            title: 'Success!',
            text: 'User updated successfully.',
            icon: 'success',
            confirmButtonText: 'OK'
        });
        

        return response.data.data.user; 
    } catch (error) {
        console.error('Error updating user:', error.message);
        
        // Show error SweetAlert
        Swal.fire({
            title: 'Error!',
            text: 'There was an error updating the user.',
            icon: 'error',
            confirmButtonText: 'Try Again'
        });
    }
};



export const fetchAllUsers = async () => {
  try {
    const response = await axios.get("/user/getAllUsers"); // Replace with your actual API endpoint
    if (response.data.success) {
      console.log("Users:", response.data.data);
      return response.data.data; // Return the user data if needed
    } else {
      console.error("Failed to fetch users:", response.data.message);
      return [];
    }
  } catch (error) {
    console.error("Error during API call:", error);
    return [];
  }
};

export const fetchUserById = async (userId) => {
    try {
      const response = await axios.get(`/user/new/${userId}`); 
      if (response.data.success) {
        return response.data.data; 
      } else {
        throw new Error(response.data.message || "Failed to fetch user.");
      }
    } catch (error) {
      console.error("Error fetching user by ID:", error.message);
      throw error; 
    }
  };

  export const updateUserById = async (userId, formData) => {
    return await axios.put(`/user/${userId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };