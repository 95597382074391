import React, {useEffect, useState} from "react";

import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";

import {
  PlusCircle,

} from "react-feather";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {Table,Image} from 'antd'
import AddUsers from "../../core/modals/usermanagement/addusers";
import EditUser from "../../core/modals/usermanagement/edituser";
import { deleteUser } from '../../service/ApiServices';
import { fetchAllUsers } from '../../service/userService';
import { API_BASE_URL } from "../../../src/environment";


const Users = () => {

  const [userData, setUserData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateUser,setUpdateUser]=useState(null)
   const [search,setSearch]=useState("");
   const [filteredUsers, setFilteredUsers] = useState([]); 
  let currentDate = new Date();  //Not work in UI

  // console.log(userData, "USER DATA ==== user.jsx")

  const handleUpdateUser = (record) => {
    setUpdateUser(record)
    setOpenEdit(true)

  };
  const handleClose = () => {
    setUpdateUser(null)
    setOpenEdit(false)

  };
  const getUsers = async () => {
    try {
      setLoading(true); // Start loading
      const userData = await fetchAllUsers(); // Fetch user data
      setUsers(userData); // Update state with the fetched data
    } catch (error) {
      console.error("Error fetching users:", error); // Log any errors
    } finally {
      setLoading(false); // Stop loading regardless of success or error
    }
  };
  useEffect(() => {
    getUsers(); // Call the getUsers function
  }, []); // Empty dependency array ensures it runs once when the component mounts

  //delete user

  const deleteUserData = async (record) => {
    try {
      // console.log(record.key,"RECORD user.jsx")
      await deleteUser(record.key); 
      const updatedUserData = userData.filter((user) => user !== record);
      // showConfirmationAlert(record); 
      setUserData(updatedUserData);
     
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const storedUserData = localStorage.getItem("user");
  const userData1 = JSON.parse(storedUserData);
  const id = userData1?.data?.uid; //
  const [userRightssub, setUserRightssub] = useState([]);
  useEffect(() => {
    const fetchUserRightssub = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/userright/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
      }
    };
  
    fetchUserRightssub(); // Fetch the data on component mount
  }, [id]);
  const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Users' && right.adds === 1);
  //const hasJobRightWithedit = userRightssub.some(right => right.right === 'Users' && right.updates === 1);
  const hasJobRightWithview = userRightssub.some(right => right.right === 'Users' && right.viewes === 1);  
  //const hasJobRightWithedel = userRightssub.some(right => right.right === 'Users' && right.deletes=== 1); 
 // const hasJobRightWithdown = userRightssub.some(right => right.right === 'Shifts' && right.fdownload=== 1); 


  useEffect(() => {
    // Fetch user data from your backend API
    fetch("http://localhost:8000/api/user/allusers")
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((data) => {

        if (!Array.isArray(data.data)) {
          throw new Error('Data is not an array');
        }

        const transformedData = data.data.map((user) => ({
          key: user._id,   //roleId change
          name: user.name,   //name->username
          email: user.email,
          role: user.role ? user.role.name : "No data",
          phone: user.phone || "No data",
          createdon: currentDate || "No data",
          status: user.status || "No data",
          password: user.password,
          confirmPassword: user.password
        }));


        setUserData(transformedData);

      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);




  // const oldandlatestvalue = [
  //   { value: "date", label: "Sort by Date" },
  //   { value: "newest", label: "Newest" },
  //   { value: "oldest", label: "Oldest" },
  // ];

  // const status = [
  //   { value: "Choose Name", label: "Choose Status" },
  //   { value: "Active", label: "Active" },
  //   { value: "InActive", label: "InActive" },
  // ];
  // const role = [
  //   { value: "Choose Role", label: "Choose Role" },
  //   { value: "AcStore Keeper", label: "Store Keeper" },
  //   { value: "Salesman", label: "Salesman" },
  // ];

  // //const dataSource = useSelector((state) => state.userlist_data);
  // //console.log(dataSource,"DATA SOURCE")
  // const [isFilterVisible, setIsFilterVisible] = useState(false);
  // const toggleFilterVisibility = () => {
  //   setIsFilterVisible((prevVisibility) => !prevVisibility);
  // };



  const columns = [
    
      {
          title: 'Profile Pic',
          dataIndex: 'profile_picture',
          key: 'profile_picture',
          render: (profile_picture) => (
              <Image
                  src={profile_picture}
                  alt="Profile Picture"
                  width={50}
                  height={50}
                  style={{
                      borderRadius: '50%',
                      objectFit: 'cover',
                  }}
                  
                  preview={{
                      zIndex: 10000,
                     
                  }}
                  // onClick={() => {
                  //     setPreviewImage(profile_picture);
                  //     setPreviewOpen(true);
                  // }}
              />
          ),
          width: "110px"
      },
    {
      title: "User Name",
      dataIndex: "name",
      render: (text, record) => (
        <span className="userimgname">
          <Link to="#" className="userslist-img bg-img">
            <ImageWithBasePath alt="" src={record.img} />
          </Link>
          <div>
            <Link to="#">{text}</Link>
          </div>
          {/* {console.log(record,"record user.jsx")} */}
        </span>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => role?.roleName || "N/A", // Safely access 'roleName' or show 'N/A' if undefined
      sorter: (a, b) => a.role?.roleName.localeCompare(b.role?.roleName), // Sort by 'roleName'
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: (text) => (
    //     <div>
    //       {text === "Active" && (
    //         <span className="badge badge-linesuccess">{text}</span>
    //       )}
    //       {text === "Inactive" && (
    //         <span className="badge badge-linedanger">{text}</span>
    //       )}
    //     </div>
    //   ),
    //   sorter: (a, b) => a.status.length - b.status.length,
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
              {hasJobRightWithview && (
                     <Link className="me-2 p-2" to="#">
                     <i
                       data-feather="eye"
                       className="feather feather-eye action-eye"
                     ></i>
                     {/* {console.log(record, "record user.jsx")} */}
                   </Link>
                )}
                {/* {hasJobRightWithedit && ( */}
                     <Link
                     className="me-2 p-2"
                     onClick={() => handleUpdateUser(record)}
                   //onClick={() => handleEditClick(record)}
                   >
                     {/* {console.log(handleUpdateUser, "handleupdate user.jsx")} */}
                     <i data-feather="edit" className="feather-edit"></i>
                   </Link>
                {/* )} */}
                {/* {hasJobRightWithedel && ( */}
                     <Link className="confirm-text p-2" to="#">
                     <i
                       data-feather="trash-2"
                       className="feather-trash-2"
                       onClick={() => showConfirmationAlert(record)}
                     ></i>
                   </Link>
                {/* )} */}
          </div>
        </td>
      ),
    },
  ];
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (record) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        deleteUserData(record);
      } else {
        MySwal.close();
      }
    });
  };

  const openAddUser = () => {
   setOpenModal(true);
  }

  const closeAddUser = () => {
    setOpenModal(false);
  }
  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    filterUsers(searchValue); // Call filter function with the updated search value
  };
  const filterUsers = (searchTerm) => {
    if (!searchTerm.trim()) {
      // If the search term is empty or contains only spaces, show all users
      setFilteredUsers(users);
      return;
    }
  
  
    const lowerCaseSearch = searchTerm.toLowerCase();
    const filtered = users.filter((user) => {
      const matchesName = user?.name.toLowerCase().includes(lowerCaseSearch);
      // Only filter by email if user.email is defined
      const matchesEmail = user?.email && user.email.toLowerCase().includes(lowerCaseSearch);
  
      return matchesName || matchesEmail;
    });
  
    setFilteredUsers(filtered);
    // Update the filtered users
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>User List</h4>
                <h6>Manage Your Users</h6>
              </div>
            </div>

            <div className="page-btn">
              {!hasJobRightWithAdd && (
                <Link
                  to=""
                  className="btn btn-added"
                  onClick={openAddUser}
                >
                  <PlusCircle className="me-2" />
                  Add New User
                </Link>
                //   <a
                //   onClick={openAddUser}
                // >
                //   <PlusCircle className="me-2" />
                //  
                // </a>
              )}
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                    value={search}
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      onChange={handleSearchChange} 
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
             
              </div>
              {/* /Filter */}
              
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={filteredUsers.length > 0 ? filteredUsers : users} loading={loading} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddUsers open={openModal} onClose={closeAddUser}/>
      <EditUser open={openEdit} onClose={handleClose} userId={updateUser?._id}/>
    </div>
  );
};

export default Users;
