import {PlusCircle} from "react-feather";
import {Link} from "react-router-dom";
import { Select } from "antd";
import {Modal, Table,Popconfirm} from "antd";
import React, {useState,useEffect} from "react";
import CreateAllowance from "./createAllowance";
import {getAllEmployeeAllowances,deleteEmployeeAllowance,getAllAllowances,fetchSearchAllowance} from '../../service/allowanceService';
import BulkUploadModal from "../../feature-module/hrm/allowanceBulkUpload"
const Allowances = () => {

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [loading,setLoading] = useState(false);
    // const [search, setSearch] = useState(null);
    const [selectedAllowance, setSelectedAllowance] = useState(null);
    const [allowances,setAllowances]=useState([])
    const [epf_number, setEpf] = useState("")
    const [allowanceType, setAllowanceType] = useState("")
    const [name, setName] = useState("")
    const [allAllowanceTypes, setAlllowanceTypes] = useState([]);
    const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);

    useEffect(() => {
        const fetchAllowances = async () => {
            setLoading(true)
            const data = await getAllEmployeeAllowances();
            setLoading(false)
            if (data) {
                setAllowances(data);
            } else {
                setAllowances([])
            }
        };
        fetchAllowances();
    }, []);

    const fetchData = async () => {
        try {
            const data = await getAllAllowances();
            setAlllowanceTypes(data);
        } catch (error) {
            console.error("Error fetching allowances:", error);
        }
    };

    useEffect(() => {
        const fetchAllowanceType = async () => {
            try {
                fetchData()
            } catch (error) {
                console.error('Error fetching types', error);
            }
        };
        fetchAllowanceType();
    }, []);

    const fetchAllowances = async () => {
        setLoading(true)
        const data = await getAllEmployeeAllowances();
        setLoading(false)
        if (data) {
            setAllowances(data);
        } else {
            setAllowances([])
        }
    };
    const handleChangeType = (value) => {
        setAllowanceType(value)
    }
    
        const getSeachAllowances =async ( ) => {
            const records = await fetchSearchAllowance({
                setLoading,
                epf_number,
                name,
                allowanceType,
            });
            console.log(records,"FETCHED DATA")
            if (records) {
               setAllowances(records)
               
            } else {
                setAllowances([])  // Ensure fallback to an empty array
            }
            //console.log(deductionList,"this is record")
        }
    
    const columns = [
        {
            title: 'Name',
            dataIndex: 'employee',
            key: 'name',
            render: (employee) => employee?.full_name || 'N/A',
        },
        {
            title: 'EPF No',
            dataIndex: 'employee',
            key: 'epf',
            render: (employee) => employee?.epf_number || 'N/A',
        },
        {
            title: 'Allowance Type',
            dataIndex: 'allowanceType',
            key: 'allowanceType',
            render: (allowanceType) => allowanceType?.name || 'N/A',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <td className="action-table-data">
                    <Link
                        className="me-2 p-2"
                        to="#"
                        onClick={() => {
                            setSelectedAllowance(record);
                            setOpenCreateModal(true);
                        }}
                    >
                        <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                    <Popconfirm
                        title="Delete the Allowance"
                        description="Are you sure to disable this Allowance?"
                        onConfirm={async () => {
                            try {
                              await deleteEmployeeAllowance(record._id);
                              await fetchAllowances();
                            } catch (error) {
                              console.error('Error while deleting allowance or fetching data:', error);
                            }
                          }}
                        onCancel={() => {
                            console.log("c")
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Link className="confirm-text p-2" >
                        <i data-feather="trash-2" className="feather-trash-2"></i>
                        </Link>
                    </Popconfirm>

                </td>
            ),
        },
    ];
    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Allowances</h4>
                            <h6>Manage your allowances</h6>
                        </div>
                    </div>
                    <div className="page-btn">
                        <a className="btn btn-added" onClick={() => setOpenCreateModal(true)}>
                            <PlusCircle className="me-2"/>
                            Create Allowance
                        </a>
                    </div>
                    <div className="page-btn">
                            {

                                <a className="btn btn-added" 
                                onClick={() => setIsBulkUploadOpen(true)}
                                >
                                    {/* <PlusCircle className="me-2" /> */}
                                    Bulk upload
                                </a>
                            }


                        </div>
                </div>
                <div className="card table-list-card">
                    <div className="card-body" style={{ maxWidth: "100%", marginRight: "20px" }}>
                        <div className="container mt-3 " style={{ maxWidth: "100%", marginLeft: "20px", marginRight: "40px" }}> {/* Added p-0 and maxWidth */}
                            <div className="row d-flex flex-wrap align-items-start justify-content-between"> {/* Added justify-content-start */}
                                <div className="col-12 col-md-4  text-start">
                                    <label className="form-label">Employee Name</label>
                                    <input
                                        type='text'
                                       value={name}
                                        className="form-control"
                                       onChange={(e) => {
                                           setName(e.target.value);
                                       }}
                                       style={{ width: "100%" }}
                                    />
                                </div>
                                <div className="col-12 col-md-3 mb-3 text-start">
                                    <label className="form-label">EPF No</label>
                                    <input
                                        className="form-control"
                                        type='text'
                                        value={epf_number}
                                        onChange={(e) => setEpf(e.target.value)}
                                        style={{ width: "100%" }}
                                    />
                                </div>

                                <div className="col-12 col-md-4 mb-3 text-start">
                                    <label className="form-label">Allowance Type Type</label>
                                    <Select
                                    className="select"
                                    options={(allAllowanceTypes || []).map((allowance) => ({
                                        value: allowance.name,
                                        label: allowance.name
                                    }))}
                                    placeholder="Select leave type"
                                    value={allowanceType}
                                    onChange={handleChangeType}
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{ width: "100%", height: "40px" }}
                                />

                                    </div>



                            </div>
                        </div>

                        <div className="container mt-0" style={{ maxWidth: "100%", marginLeft: "20px", marginRight: "40px" }}>
                            <div className="row d-flex align-items-center justify-content-start">

                                 <div className="col-12 col-md-2 mb-3 text-start">
                                   
                                    <button
                                            className="btn btn-submit"
                                            onClick={getSeachAllowances}
                                            style={{ height: "38px", width: "55%" }}
                                        >
                                            Submit
                                        </button>
                                    
                                </div>

                            </div>
                        </div>



                    </div>
                </div>
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                        </div>
                        <div className="table-responsive">
                            <Table loading={loading} columns={columns} dataSource={allowances} rowKey="value"
                                    pagination={{ pageSize: 10 }}/>
                        </div>
                    </div>
                </div>
            </div>


                <Modal
                    zIndex={200000}
                    open={openCreateModal}
                    onCancel={()=>setOpenCreateModal(false)}
                    footer={null}
                    maskClosable={false}
                    closable={false}
                >
                <CreateAllowance selectedAllowance={selectedAllowance?._id} handleCancel={() => {
                    setSelectedAllowance(null);
                    setOpenCreateModal(false);
                }} 
                handleReload={fetchAllowances}/>
                </Modal>
            <BulkUploadModal
                handleCancel={() => setIsBulkUploadOpen(false)}
                isVisible={isBulkUploadOpen}
                onUploadData={fetchAllowances}
            />

        </div>

    )
}

export default Allowances;
