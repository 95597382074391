import { Spin, Select } from "antd";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getAllEmployeesWithoutPaginationMethod } from '../../service/ApiServices';
import { getAllAllowances, createEmployeeAllowance, getAllowanceById, updateEmployeeAllowance } from '../../service/allowanceService';
const CreateAllowance = ({ selectedAllowance, handleCancel, handleReload }) => {

    const [loading, setLoading] = useState(false);
    const [employeeData, setEmployeeData] = useState([]);
    const [allowanceTypes, setAlllowanceTypes] = useState([]);
    const [selectedAllowanceType, setSelectedAllowanceType] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [amount, setAmount] = useState();

    const fetchData = async () => {
        try {
            const data = await getAllAllowances();
            setAlllowanceTypes(data);
        } catch (error) {
            console.error("Error fetching allowances:", error);
        }
    };
    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                fetchData()
                await getAllEmployeesWithoutPaginationMethod({
                    setEmployeeData
                });
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };
        fetchEmployees();
    }, []);
    useEffect(() => {
        const fetchAllowanceDetails = async () => {
            if (selectedAllowance) {
                try {
                    const allowanceDetails = await getAllowanceById(selectedAllowance);
                    if (allowanceDetails) {
                        setSelectedEmployee(allowanceDetails.employee);
                        setSelectedAllowanceType(allowanceDetails.allowanceType);
                        setAmount(allowanceDetails.amount);
                    }
                } catch (error) {
                    console.error("Error fetching allowance details:", error);
                }
            }
        };

        fetchAllowanceDetails();
    }, [selectedAllowance]);


    const handleChangeEmployee = (value) => {
        setSelectedEmployee(value)
    }
    const handleSubmit = async () => {
        if (selectedAllowance) {
            await updateEmployeeAllowance(selectedAllowance, selectedEmployee, selectedAllowanceType, amount);
            handleReload()
            handleClickCancel()
        } else {

            setLoading(true);
            await createEmployeeAllowance(selectedEmployee, selectedAllowanceType, amount);
            handleReload()
            handleClickCancel()
            setLoading(false);
        }
    }
    
    const handleClickCancel = () => {
        handleCancel()
        setSelectedEmployee(null);
        setSelectedAllowanceType(null);
        setAmount("")

    };
    const handleReset = () => {

        setSelectedEmployee(null);
        setSelectedAllowanceType(null);
        setAmount("")

    };
    return (
        <div className="modal-content">
            <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                    <h4>{selectedAllowance ? "Edit Allowance" : "Create Allowances "}</h4>
                    <p className="mb-0">Fill in the required fields to create a allowance</p>
                </div>
            </div>
            <div className="modal-body custom-modal-body">
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="input-blocks">
                                <label htmlFor="selectEmployee">Select Employee</label>
                                <Select
                                    className="select"
                                    options={employeeData}
                                    placeholder="Select an employee"
                                    value={selectedEmployee}
                                    onChange={handleChangeEmployee}
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{ width: "100%", height: "40px" }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="input-blocks">
                                <label>Select Allowance Type</label>
                                <Select
                                    className="select"
                                    options={(allowanceTypes || []).map((allowance) => ({
                                        value: allowance._id,
                                        label: allowance.name
                                    }))}
                                    placeholder="Select an allowance type"
                                    value={selectedAllowanceType}
                                    onChange={(e) => setSelectedAllowanceType(e)}
                                    style={{ width: "100%", height: "40px" }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="input-blocks">
                                <label>Enter Amount</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    // options={allowanceTypes}
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                        </div>

                    </div>
                </form>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={handleClickCancel}
                    >
                        Close
                    </button>
                    <div>
                        <button
                            type="button"
                            className="btn btn-reset me-2"
                            onClick={handleReset}
                        >
                            Reset
                        </button>
                        <button className="btn btn-submit" onClick={handleSubmit}>
                            {loading ? <Spin size='small' /> : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

CreateAllowance.propTypes = {
    handleReload: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    selectedAllowance: PropTypes.object.isRequired
};

export default CreateAllowance;