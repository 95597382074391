import axios from "axios";
import { BASE_URL } from "../environment";
import Swal from "sweetalert2"; 
export const getLeavesEmployee = async () => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/employee-leave`
    );
    console.log("Leave Employee Fetched..", response.data.data);
    return response.data.data;
  } catch (error) {
    console.log("Error fetching Leave Employees", error.message);
  }
};




export const getSingleLeaveEmployee = async (id) => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/employee-leave/${id}`
    );
    //console.log("Single Leave Employee Fetched..", response.data.data);
    return response.data.data;
  } catch (error) {
    console.log("Error fetching Single Leave Employees", error.message);
  }
};

export const createLeaveEmployee = async (leaveData) => {
  try {
    const response = await axios.post(
      `/employee-leave`,
      {
        leavetype: leaveData.leavetype,
        leaveDays: leaveData.leaveDays, // Array of dates and days (Full Day / Half Day)
        reason: leaveData.reason,
        startDate: leaveData.startDate,
        endDate: leaveData.endDate,
        approval: leaveData.approval || false,
        userId: leaveData.userId,
        name: leaveData.name,
        duration:leaveData.duration,
        shortLeave:leaveData.shortLeave,
        shortLeaveTime:leaveData.shortLeaveTime
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to create employee leave:", error.message);
    throw error;
  }
};
export const updateLeaveEmployee = async (id, leaveDetails) => {
  try {
    const response = await axios.put(
      `http://${BASE_URL}:8000/api/employee-leave/${id}`,
      leaveDetails
    );
    return response.data;
  } catch (error) {
    console.error("Failed to Update employee leave:", error.message);
    throw error;
  }
};
export const deleteLeaveEmployee = async (id) => {
  try {
    const response = await axios.delete(
      `http://${BASE_URL}:8000/api/employee-leave/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to Update employee leave:", error.message);
    throw error;
  }
};

// Fetch leave type and count
export const fetchLeaveTypeAndCount = async (id) => {
  try {
    const response = await axios.get(
      `/allocate-leave-user/${id}`
    );
    console.log("response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Failed fetching leave allocation data:", error.message);
    throw error;
  }
};

//this for status update in admin leavs
export const updateApprovalStatus = async (id, approval) => {
  try {
    const response = await axios.put(`/employee-leave/approval/${id}`, {
      approval,
    });

    console.log(response.data.message);
    return response.data.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Error updating approval status:",
        error.response.data.message
      );
    } else {
      console.error("Error:", error.message);
    }
  }
};

//for table data // with employee details //adminLeaves
export const getEmployeeLeaveWithEmployee = async (userId,setLoading) => {
  try {
    setLoading(true)
    const response = await axios.get(`/employee-leave`, {
      params: {
         userId:userId
      }, headers: {
          'Cache-Control': 'no-cache'
      }
  });
  setLoading(false)
  // if(!response.data){
  //   setLoading(false)
  // }
    return response.data;
  } catch (error) {
    setLoading(false)
    console.error("Error fetching employee leaves:", error.message);
    throw error;
  }
};

export const getSearchResults =async ({name,epf_number,startDate,endDate,leaveType,branch,setLoading,userId}) => {
  try {
    setLoading(true)
      const response = await axios.get(`/search-leaves`, {
          params: {
              name:name,
              epf_number:epf_number,
              startDate:startDate,
              endDate:endDate,
              leaveType:leaveType,
              branch:branch,
              userId:userId

          }, headers: {
              'Cache-Control': 'no-cache'
          }
      });

      setLoading(false) 
      console.log(response,"This is Response")
      return response.data.data;
      
  } catch (error) {
      
      Swal.fire({
          title: 'Error!',
          text: error.response ? error.response.data.message : 'An error occurred while fetching leaves.',
          icon: 'error',
          confirmButtonText: 'Try Again'
      });
  
   setLoading(false)
  }
};
/////////////////////employee Leave Service Dilshan

export const getUserLeaves = async (userId) => {
  try {
    console.log("API USER",userId)
    const response = await axios.get(`/user-leaves/${userId}`);
    return response.data; // Returns the leave allocation data
  } catch (error) {
    console.error("Error fetching allocated leaves:", error.response?.data || error.message);
    throw error;
  }
};

export const getShortLeaves = async (userId) => {
  try {
   // console.log("API USER",userId)
    const response = await axios.get(`/employee-leave/short-leaves/${userId}`);
    return response.data; // Returns the leave allocation data
  } catch (error) {
    console.error("Error fetching allocated leaves:", error.response?.data || error.message);
    throw error;
  }
};