import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert
const currentMonth = new Date().getMonth() + 1
const currentYear = new Date().getFullYear();
export const fetchPayrollByMonthAndYear = async ({month, year,setLoading}) => {
    try {setLoading(true)
        const response = await axios.get(`/get-payrolls`, {
            params: {
                month: month,
                year: year
            }, headers: {
                'Cache-Control': 'no-cache'
            }
        });

        //console.log('Payroll Records:', response.data.payrollRecords);
        setLoading(false) 
        return response.data.payrollRecords;
        
    } catch (error) {
       
        if(month !== currentMonth || year !== currentYear){
        
        Swal.fire({
            title: 'Error!',
            text: error.response ? error.response.data.message : 'An error occurred while fetching payroll records.',
            icon: 'error',
            confirmButtonText: 'Try Again'
        });
    }
     setLoading(false)
    }
};

export const fetchPayrollBySearch = async ({month, year,name,epf_number,center,branch,setLoading}) => {
    try {setLoading(true)
        const response = await axios.get(`/search-payrolls`, {
            params: {
                month: month,
                year: year,
                name:name,
                epf_number:epf_number,
                center:center,
                branch:branch

            }, headers: {
                'Cache-Control': 'no-cache'
            }
        });

        //console.log('Payroll Records:', response.data.payrollRecords);
        setLoading(false) 
        return response.data;
        
    } catch (error) {
       
        if(month !== currentMonth || year !== currentYear){
        
        Swal.fire({
            title: 'Error!',
            text: error.response ? error.response.data.message : 'An error occurred while fetching payroll records.',
            icon: 'error',
            confirmButtonText: 'Try Again'
        });
    }
     setLoading(false)
    }
};


// Function to fetch all payrolls
export const fetchAllPayrolls = async (month,year) => {
    try {
        const response = await axios.get('/get-saved-payrolls', {
            params: {
                month:month,
                year:year,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching payrolls:', error.message);
        throw error; // Rethrow error for further handling
    }
};


export const calculatePayrolls = async (startDate, endDate) => {
    try {
        const response = await axios.get('/payroll-controller', {
            params: {
                startDate:startDate,
                endDate:endDate,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error calculating payrolls:', error.message);
        throw error;
    }
};


