import React, { useEffect, useState } from "react";
import { Popover, Button, Table, Modal,Tag,Tabs } from "antd";
import { PlusCircle } from "react-feather";
import {} from "feather-icons-react/build/IconComponents";

import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  getSingleLeaveEmployee,
  deleteLeaveEmployee,
   
  getUserLeaves,
  getShortLeaves
} from "../../service/leavesEmployeeApiService";
import AddLeaveEmployee from "../../core/modals/hrm/addleaveemployee";
import EditLeaveEmployee from "../../core/modals/hrm/editleaveemployee";
const { TabPane } = Tabs;
const LeavesEmployee = () => {
  const [leaveEmployees, setLeaveEmployees] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedEmployeeLeave, setSelectedEmployeeLeave] = useState(null);
  const [leaveBalances, setLeaveBalances] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false); //edit leaveemployee
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); //add leaveemployee
  const cardClasses = ['light-card', "danger-card"]; 
  const [shortLeaves, setShortLeaves] = useState([]);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleAddModalOk = () => {
    setIsAddModalOpen(false);
  };

  const handleAddModalCancel = () => {
    setIsAddModalOpen(false);
  };

  useEffect(() => {
    FetchLeaveEmployees();
    FetchUserLeaves();
    FetchShortLeaves()
  }, []);
  const storedUserData = localStorage.getItem("user");
  const userData = JSON.parse(storedUserData);
  const userObj = userData?.data;
  console.log(userObj,"OBKJJJJJJJJJJJJJJJJJ")
  const FetchLeaveEmployees = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user.data._id;
      const leaveEmployees = await getSingleLeaveEmployee(userId);
      setLeaveEmployees(leaveEmployees);
      
    } catch (error) {
      console.log(error.message);
    }
  };
  const FetchUserLeaves = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user.data._id;
      console.log("User:", userId);
      const response = await getUserLeaves(userId);
      setLeaveBalances(response.leaveBalances);
      console.log("Leave Balances for Employee:", response);
    } catch (error) {
      console.error("Failed to fetch leave balances:", error.message);
    }
  };
  const FetchShortLeaves = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user.data._id;
      //console.log("User:", userId);
      const response = await getShortLeaves(userId);
      setShortLeaves(response)
      console.log(shortLeaves,"SHORT LEAVES")
    } catch (error) {
      console.error("Failed to fetch leave balances:", error.message);
    }
  };


  const filteredData = leaveEmployees?.filter((leave) =>
    leave?.leavetype?.name?.toLowerCase().includes(search?.toLowerCase() || "")
  ) || [];
  

  const handleDelete = (id) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteLeaveEmployee(id);

          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "Leave has been deleted successfully.",
              icon: "success",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
          FetchLeaveEmployees();
        } catch (error) {
          console.error("Failed to delete employee leave:", error);
          MySwal.fire({
            title: "Error",
            text: "Failed to delete leave.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } else {
        MySwal.close();
      }
    });
  };

  const columns = [
    {
      title: "Type",
      dataIndex: ["leavetype", "name"], // Updated to use nested array notation for nested data
      key: "leavetype.name",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (date) => (date ? new Date(date).toLocaleDateString() : "-"),
    },
    { title: "Leave Count", dataIndex: "duration", key: "duration",
      render: (duration) => duration===0?"-":duration,
     },
    {
      title: "Applied On",
      dataIndex: "date",
      key: "date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (reason) => (
        <Popover content={reason}>
          <Button type="link">Hover to View</Button>
        </Popover>
      ),
    },
    {
      title: "Approval",
      dataIndex: "approval",
      key: "approval",
      render: (approval) => {
        if (approval === null) {
          return (
            <Tag className="badges" style={{ backgroundColor: '#f0ad4e', color: '#fff' }}>
              Pending
            </Tag>
          );
        } else if (approval === true) {
          return (
            <Tag className="badges status-badge">
              Approved
            </Tag>
          );
        } else if (approval === false) {
          return (
            <Tag className="badges unstatus-badge">
              Rejected
            </Tag>
          );
        }
      }
    },
    {
      title: "Action",
      key: "action",
      render: (leave) => (
        <div className="edit-delete-action">
          <Link
            className="me-2 p-2"
            to="#"
            onClick={() => {
              setSelectedEmployeeLeave(leave);
              setIsModalOpen(true);
            }}
          >
            <i data-feather="edit" className="feather-edit" />
          </Link>
          <Link
            className="confirm-text p-2"
            to="#"
            onClick={() => handleDelete(leave._id)}
          >
            <i data-feather="trash-2" className="feather-trash-2" />
          </Link>
        </div>
      ),
    },
  ];
  const columns1 = [
    {
      title: "Leave Time",
      dataIndex: "leave_time", // Updated to use nested array notation for nested data
      key: "leave_time",
    },
 
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
  
    {
      title: "Action",
      key: "action",
      render: () => (
        <td className="action-table-data">
          <Link
            className="me-2 p-2"
            to="#"
            // onClick={() => {
            //   setSelectedEmployeeLeave(leave);
            //   setIsModalOpen(true);
            // }}
          >
            <i data-feather="edit" className="feather-edit" />
          </Link>
          <Link
            className="confirm-text p-2"
            to="#"
           // onClick={() => handleDelete(leave._id)}
          >
            <i data-feather="trash-2" className="feather-trash-2" />
          </Link>
        </td>
      ),
    },
  ];
  const handleReload = () => {
    FetchLeaveEmployees();
    FetchShortLeaves();
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Leaves</h4>
              <h6>Manage your Leaves</h6>
            </div>
          </div>
          {/* Collapse and Refresh Buttons */}

          <div className="page-btn">
          <a className="btn btn-added"  onClick={() => setIsAddModalOpen(true)}>
              <PlusCircle className="me-2" />
             Apply Leave
            </a>
          </div>
        </div>
        <div className="col-xl-8 col-lg-12 col-md-8 d-flex">
          <div className="card w-100">
            <div className="card-body">
              <h5>Leave Count Overview This Month</h5>
              <ul className="widget-attend">

                {leaveBalances.map((balance, index) => (
                  <li key={index} className="box-attend" >
                    <div className={cardClasses[index % cardClasses.length]}>
                      {/* Card classNames: light-card, warning-card, success-card */}
                      <h4 style={{width:70}}>{balance.leaveCount}</h4>
                      <h6>{balance.leaveType.name}</h6>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="card table-list-card">
          <div className="card-body">
          <Tabs defaultActiveKey="1" >
              {/* First Tab */}
              <TabPane 
               tab={<span style={{ fontWeight: 'bold', marginLeft:10 }}>Leaves</span>}
              key="1">
                <div className="table-top">
                  <div className="search-set">
                    <div className="search-input">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table
                    dataSource={filteredData}
                    columns={columns}
                    rowKey="_id"
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              </TabPane>

              {/* Second Tab */}
              <TabPane tab={<span style={{ fontWeight: 'bold', marginLeft:10 }}>Short Leaves</span>} key="2">
                <div className="table-top">
                  <div className="search-set">
                    <div className="search-input">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search"
                        // value={search}
                       // onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table
                    dataSource={shortLeaves}
                    columns={columns1}
                    rowKey="_id"
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
      {/* Ant Design Modal for AddLeaveEmployee */}
      <Modal
        zIndex={10000}
        open={isAddModalOpen}
        onOk={handleAddModalOk}
        onCancel={handleAddModalCancel}
        footer={null}
      >
        <AddLeaveEmployee
          handleCancel={handleAddModalCancel}
          leaveBalances={leaveBalances}
          FetchLeaveEmployees={handleReload}
        />
      </Modal>

      {/* Modal for Editing Leave */}
      <Modal
        zIndex={10000}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {selectedEmployeeLeave && (
          <EditLeaveEmployee
            selectedEmployeeLeave={selectedEmployeeLeave}
            handleCancel={handleCancel}
          />
        )}
      </Modal>
    </div>
  );
};

export default LeavesEmployee;
